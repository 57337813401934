import React, { useState } from 'react';
import { useStore } from '../../../store/useStore';
import { apiClient } from '../../apiCall';

import Timestamp from '../common/Timestamp';
import UserName from '../common/UserName';

import LastModification from '../common/LastModification';
import Loader from '../common/Loader';


export default function PlaceNotesAndHistory(props) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const [commentState, setCommentState] = useState({
		isOpen: false,
		message: '',
	});
	// const mode = props.mode === 'places' ? 'places' : 'events';

	const mode = 'destinations';

	if (!(state?.destinations?.items ?? false)) {
		return <Loader className="dark"></Loader>;
	}

	const placeData = state[mode].items.find((place) => {
		return place.id === parseInt(props.id);
	});

	// const fetchRevisions = () => {
	// 	dispatch({ type: "REVISIONS_LOADING", payload: {} }, [dispatch]);
	// 	apiCall.GET(`/destination/${placeData.id}/history/`);
	// };

	const addNote = () => {
		dispatch({ type: 'NOTE_ADD', payload: {} }, [dispatch]);
		apiCall.EDIT(mode, placeData.id, {
			note: [...placeData.note, { message: commentState.message }],
		});
	};

	const onMessageChange = (event) => {
		setCommentState({ ...commentState, message: event.currentTarget.value });
	};

	const toggleVisibility = () => {
		setCommentState({ ...commentState, isOpen: !commentState.isOpen });
	};

	const CommentCount = () => {
		return (
			<span className="flex">
				{placeData && placeData.note && placeData.note.length} kommenttia
			</span>
		);
	};

	const Note = (props) => {
		const { note } = props;
		return (
			<div className="note">
				<p>{note.message}</p>

				<span className="user">
					- <UserName user_id={note.author_user_id} />{' '}
					<Timestamp timestamp={note.created_at} />
				</span>
			</div>
		);
	};

	const rawHistory =
		placeData && placeData.revision_history
			? [...placeData.revision_history]
			: [];

	const history = rawHistory.reverse().reduce((a, b) => {
		var i = a.findIndex((x) => x.created_at === b.created_at);
		if (i === -1) {
			a.push(b);
		}

		return a;
	}, []);

	if (placeData && placeData.id) {
		return (
			<div className="notesandhistory">
				<div className="row togglenotes" onClick={toggleVisibility}>
					<div className="col">
						<CommentCount />
					</div>
					<div className="col revisions">
						<LastModification placeData={placeData} />
					</div>
				</div>
				{commentState.isOpen && (
					<div className="row">
						<div className="col">
							{/* <CommentCount /> */}
							<div className="addnote">
								<textarea
									value={commentState.message}
									onChange={onMessageChange}
									placeholder="Kirjoita kommentti"
								></textarea>
								<button onClick={addNote}>Lisää kommentti</button>
							</div>
							<div className="notelist">
								{placeData.note &&
									[...placeData.note].reverse().map((item) => {
										return <Note key={item.id} note={item} />;
									})}
							</div>
						</div>
						<div className="col revisions">
							{/* <LastModification placeData={placeData} /> */}
							<div className="revlist">
								{history &&
									history.map((lastMod) => {
										return (
											<div key={lastMod.id}>
												<UserName user_id={lastMod.user_id} />
												{', '}
												<Timestamp
													timestamp={lastMod.created_at}
													pure={true}
													format={'dd.MM.yyyy HH:mm:ss'}
												/>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	} else {
		return <></>;
	}
}
