import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

import { userInitialState, userReducer } from './user';
import { commonInitialState, commonReducer } from './common';
import { categoriesInitialState, categoriesReducer } from './categories';
import { companycategoriesInitialState, companycategoriesReducer } from './companycategories';
import { regionsInitialState, regionsReducer } from './regions';
import { companyregionsInitialState, companyregionsReducer } from './companyregions';
import { packagesInitialState, packagesReducer } from './packages';
import {
	clientPackagesInitialState,
	clientPackagesReducer,
} from './clientpackages';
import { destinationsInitialState, destinationsReducer } from './destinations';
import { companiesInitialState, companiesReducer } from './companies';
import { kohdekortitInitialState, kohdekortitReducer } from './kohdekortit';

import { usersInitialState, usersReducer } from './users';
// import { sitecontentInitialState, sitecontentReducer } from "./sitecontent";
// import { placesInitialState, placesReducer } from "./places";
// import { placeEditorInitialState, placeEditorReducer } from "./placeEditor";
// import { eventsInitialState, eventsReducer } from "./events";
// import { eventEditorInitialState, eventEditorReducer } from "./eventEditor";
import { instagramInitialState, instagramReducer } from './instagram';
// import { places, instagramReducer } from "./old/instagram";

const initialState = {
	common: commonInitialState,
	user: userInitialState,
	categories: categoriesInitialState,
	companycategories: companycategoriesInitialState,
	regions: regionsInitialState,
	companyregions: companyregionsInitialState,
	packages: packagesInitialState,
	clientpackages: clientPackagesInitialState,
	destinations: destinationsInitialState,
	companies: companiesInitialState,
	kohdekortit: kohdekortitInitialState,
	// place: placeEditorInitialState,
	users: usersInitialState,
	// places: placesInitialState,
	// sitecontent: sitecontentInitialState,
	// events: eventsInitialState,
	// event: eventEditorInitialState,
	instagram: instagramInitialState,
};

const StoreContext = createContext(initialState);

const reducer = (state, action) => {
	const {
		common,
		user,
		categories,
		companycategories,
		regions,
		companyregions,
		clientpackages,
		packages,
		destinations,
		companies,
		kohdekortit,
		// place,
		// categories,
		users,
		// places,
		// events,
		// event,
		// sitecontent,
		instagram,
	} = state;


	const currentState = {
		common: commonReducer(common, action),
		user: userReducer(user, action),
		categories: categoriesReducer(categories, action),
		companycategories: companycategoriesReducer(companycategories, action),
		regions: regionsReducer(regions, action),
		companyregions: companyregionsReducer(companyregions, action),
		packages: packagesReducer(packages, action),
		clientpackages: clientPackagesReducer(clientpackages, action),
		destinations: destinationsReducer(destinations, action),
		companies: companiesReducer(companies, action),
		kohdekortit: kohdekortitReducer(kohdekortit, action),
		users: usersReducer(users, action),
		// places: placesReducer(places, action),
		// place: placeEditorReducer(place, action),
		// events: eventsReducer(events, action),
		// event: eventEditorReducer(event, action),
		// sitecontent: sitecontentReducer(sitecontent, action),
		instagram: instagramReducer(instagram, action),
	};

	return currentState;
};

export const StoreProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<StoreContext.Provider value={{ state, dispatch }}>
			{children}
		</StoreContext.Provider>
	);
};

StoreProvider.propTypes = {
	children: PropTypes.node,
};

export const useStore = (store) => {
	const { state, dispatch } = useContext(StoreContext);
	return { state, dispatch };
};
