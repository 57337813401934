import React from 'react';
import { ToastContainer } from 'react-toastify';
import App from './App';
import {
	BrowserRouter
} from 'react-router-dom';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });
		// You can also log the error to an error reporting service
		console.error(error, info);
		// logErrorToMyService(error, info);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="errorFrame">
					<h1>Valitettavasti jotain meni pieleen</h1>
					<p>Odota hetki ja lataa sivu uudelleen</p>
				</div>
			);
		}
		return this.props.children;
	}
}

const AppContainer = () => {
	return (
		<ErrorBoundary>
			<BrowserRouter>
				<App />
			</BrowserRouter>
			<ToastContainer />
		</ErrorBoundary>
	);
};

export default AppContainer;
