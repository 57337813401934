import React, { useEffect } from 'react';
import {
	useLocation,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import './Styles/App.scss';
import Header from './Components/common/Header';
import Footer from './Components/common/Footer';
import Frontpage from './Views/Frontpage';
import { useStore } from '../store/useStore';
import { apiClient } from './apiCall';
import { useTracking } from './Helpers/useTracking';
import LoginForm from './Views/LoginForm';
import ContentPage from './Views/ContentPage';
// import Flow from './Views/Flow';

import Company from './Views/admin/Company';
import Destination from './Views/admin/Destination';
import Dashboard from './Views/admin/Dashboard';

import Loader from './Components/common/Loader';
import AdminNav from './Components/common/AdminNav';
import PlacesList from './Components/admin/PlacesList';
import CompaniesList from './Components/admin/CompaniesList';
// import AddNewCompany from './Components/admin/AddNewCompany';
import ClientPackagesList from './Components/admin/ClientPackagesList';
import ClientPackage from './Components/admin/ClientPackage';
import AddNewDestination from './Components/admin/AddNewDestination';
import Categories from './Components/admin/Categories';

const Page = ({ children }) => {
	let location = useLocation();

	const classs = location.pathname.replace(/[^a-z0-9]/g, ' ');

	return (
		<>
			<Header />
			<main className={classs}>{children}</main>
			<Footer />
		</>
	);
};

const AdminPage = ({ children }) => {
	let location = useLocation();

	const classs = location.pathname.replace(/[^a-z0-9]/g, ' ');

	return (
		<div className="admin">
			<AdminNav location={location} />
			<main className={`right ${classs}`}>{children}</main>
			{/* <Footer /> */}
		</div>
	);
};

const AdminRoute = ({ url }) => {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	// const fetchCategories = () => {
	// 	if (!state.categories.loading) {
	// 		dispatch({ type: 'CATEGORIES_LOADING' });
	// 		apiCall.GET('categories');
	// 	}
	// };

	// const fetchRegions = () => {
	// 	if (!state.regions.loading) {
	// 		dispatch({ type: 'REGIONS_LOADING' });
	// 		apiCall.GET('regions');
	// 	}
	// };

	const fetchClientPackages = () => {
		if (!state.clientpackages.loading) {
			dispatch({ type: 'CLIENTPACKAGES_LOADING' });
			apiCall.GET('clientpackages');
		}
	};

	const fetchDestinations = () => {
		if (!state.destinations.loading) {
			dispatch({ type: 'DESTINATIONS_LOADING' });
			apiCall.GET('destinations');
		}
	};

	// const fetchCompanies = () => {
	// 	if (!state.companies.loading) {
	// 		dispatch({ type: 'COMPANIES_LOADING' });
	// 		apiCall.GET('companies');
	// 	}
	// };

	const fetchUsers = () => {
		if (!state.destinations.loading) {
			dispatch({ type: 'USERS_LOADING' });
			apiCall.GET('users');
		}
	};

	// const fetchKohdekortit = () => {
	// 	if (!state.kohdekortit.loading) {
	// 		dispatch({ type: 'KOHDEKORTIT_LOADING' });
	// 		apiCall.GET('kohdekortit');
	// 	}
	// };

	useEffect(() => {
		fetchDestinations();
		// fetchCompanies();
		fetchUsers();
		// fetchCategories();
		// fetchRegions();
		fetchClientPackages();
		// fetchKohdekortit();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (state.destinations.loading || state.users.loading) {
		return (
			<main>
				<Loader className="dark" />
			</main>
		);
	}

	return (
		<>
			<Route path={`${url}/`} exact>
				<AdminPage>
					<Dashboard />
				</AdminPage>
			</Route>
			<Route path={`${url}/add-destination`}>
				<AdminPage>
					<AddNewDestination />
				</AdminPage>
			</Route>
			<Route path={`${url}/companies`}>
				<AdminPage>
					<CompaniesList />
				</AdminPage>
			</Route>
			<Route
				path={`${url}/company/:id`}
				render={({
					match: {
						params: { id },
					},
				}) => {
					return (
						<AdminPage>
							<Company id={id} />
						</AdminPage>
					);
				}}
			></Route>
			<Route path={`${url}/destinations`}>
				<AdminPage>
					<PlacesList />
				</AdminPage>
			</Route>
			<Route
				path={`${url}/destination/:id`}
				render={({
					match: {
						params: { id },
					},
				}) => {
					return (
						<AdminPage>
							<Destination id={id} />
						</AdminPage>
					);
				}}
			></Route>
			<Route path={`${url}/categories`}>
				<AdminPage>
					<h1>Categories</h1>
					{/* <Categories /> */}
				</AdminPage>
			</Route>
			<Route path={`${url}/clientpackages`}>
				<AdminPage>
					<ClientPackagesList />
				</AdminPage>
			</Route>
			<Route
				path={`${url}/clientpackage/:id`}
				render={({
					match: {
						params: { id },
					},
				}) => {
					return (
						<AdminPage>
							<ClientPackage id={id} />
						</AdminPage>
					);
				}}
			></Route>
			<Route path={`${url}/packages`}>
				<AdminPage>
					<h1>Packages</h1>
					<ClientPackagesList />
				</AdminPage>
			</Route>
			<Route path={`${url}/regions`}>
				<AdminPage>
					<h1>Regions</h1>
				</AdminPage>
			</Route>
		</>
	);
};

function App() {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	useTracking('UA-71409951-24');

	const fetchCategories = () => {
		if (!state.categories.loading) {
			dispatch({ type: 'CATEGORIES_LOADING' });
			apiCall.GET('categories');
		}
	};

	const fetchRegions = () => {
		if (!state.regions.loading) {
			dispatch({ type: 'REGIONS_LOADING' });
			apiCall.GET('regions');
		}
	};

	// const fetchCompanyCategories = () => {
	// 	if (!state.companycategories.loading) {
	// 		dispatch({ type: 'COMPANYCATEGORIES_LOADING' });
	// 		apiCall.GET('companycategories');
	// 	}
	// };

	// const fetchCompanyRegions = () => {
	// 	if (!state.companyregions.loading) {
	// 		dispatch({ type: 'COMPANYREGIONS_LOADING' });
	// 		apiCall.GET('companyregions');
	// 	}
	// };
	// const fetchPackages = () => {
	// 	if (!state.regions.loading) {
	// 		dispatch({ type: 'PACKAGES_LOADING' });
	// 		apiCall.GET('packages');
	// 	}
	// };

	useEffect(() => {
		fetchCategories();
		// fetchCompanyRegions();
		// fetchCompanyCategories();
		fetchRegions();
		// fetchPackages();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (
		// state.companyregions.loading ||
		// state.companycategories.loading ||
		state.categories.loading ||
		state.regions.loading
	) {
		return (
			<main>
				<Loader className="dark" />
			</main>
		);
	}

	return (
		<Switch>
			<Route exact path="/">
				<Page>
					<Frontpage />
				</Page>
			</Route>
			<Route path="/kayttoehdot">
				<Page>
					<ContentPage page="TermsOfService" />
				</Page>
			</Route>
			<Route path="/rekisteriseloste">
				<Page>
					<ContentPage page="PrivacyPolicy" />
				</Page>
			</Route>
			{/* <Route
				path="/osallistu/:hash?"
				render={(props) => (
					<Page>
						<Flow {...props} />
					</Page>
				)}
			/> */}
			{/* <Route path="/kirjaudu">
				<Page>
					<LoginForm />
				</Page>
			</Route> */}
			<Route
				path="/admin"
				render={({ match: { url } }) => {
					return state.user.loggedIn === true ? (
						<AdminRoute url={url} />
					) : (
						<LoginForm />
					);
				}}
			/>
			<Route>
				<Redirect to="/" />
			</Route>
		</Switch>
	);
}

export default App;
