import { config } from "../App/config";
import { toast } from "react-toastify";

const localStorageKey = "user";

const defaultState = {
	loggedIn: false,
	password: config.isDev ? "letmein" : "",
	email: config.isDev ? "autologger@example.com" : "",
	loading: false,
	token: null,
};

export const userInitialState = (() => {
	let state = window.localStorage.getItem(localStorageKey)
		? JSON.parse(window.localStorage.getItem(localStorageKey))
		: defaultState;
	state.loading = false;
	return state;
})();

export const userReducer = (state = userInitialState, action) => {
	let user = state;
	switch (action.type) {
		case "LOGINFORM_INPUT":
			var ns = { ...state, [action.key]: action.value };

			user = ns;
			break;

		case "LOGIN":
			user = {
				...state,
				loggedIn: false,
				loading: true,
				token: null,
			};
			break;
		case "LOGIN_SUCCESS":
			user = {
				...state,
				loggedIn: true,
				loading: false,
				token: action.payload.headers.authentication,
			};
			break;

		case "LOGIN_FAIL":
			toast.error(
				"Kirjautuminen epäonnistui, tarkista tunnuksesi ja yritä uudelleen"
			);
			user = {
				...state,
				loggedIn: false,
				loading: false,
				token: null,
			};
			break;
		case "LOGOUT":
			user = {
				...state,
				loggedIn: false,
				loading: false,
				token: null,
			};
			window.localStorage.setItem(localStorageKey, JSON.stringify(user));
			window.location = "/";

			break;

		default:
			user = state;
			break;
	}

	window.localStorage.setItem(localStorageKey, JSON.stringify(user));

	return user;
};
