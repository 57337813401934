/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { config } from '../../config';
import { useStore } from '../../../store/useStore';
import GoogleMapEmbed from '../common/GoogleMapEmbed';
import { getErrors, isCompany, isDestination, urlWithHttp } from '../../Helpers/PlaceHelpers';
import SectionEditor from '../admin/SectionEditor';
import InstagramFeed from '../common/InstagramFeed';
import { validateKohde } from '../../Helpers/PlaceHelpers';

import {
	CustomInput,
	CustomNestedInput,
	CustomCheckboxes,
	CustomCheckbox,
	CustomSwitch,
	CustomRadio,
	CustomSelect,
	CustomPictureSelector,
	// createHandlers,
	CustomRichTextArea,
} from '../common/CustomInput';

export const ViewBasicInfo = ({
	data,
	handleOnChange,
	handleOnCKEditorChange,
}) => {
	const { state } = useStore();
	const packageOptions = (state?.clientpackages?.items ?? []).map((i) => {
		return {
			id: i.id,
			name: i.name,
		};
	});

	return (
		<>
			<a id="tiedot"></a>
			<div>
				<h2>Perustiedot</h2>
				<fieldset>
					<p>
						{/* Alla olevat tiedot on haettu Google My Business -palvelusta ja
					käytämme näitä tietoja 100 syytä -sivustolla ohjaamaan kuluttajaa
					palvelujenne pariin. */}
					</p>
					<div className="multi-input-wrapper">
						<div
							className="input-wrapper"
							style={{ flexBasis: '10em', flexGrow: 0 }}
						>
							<CustomInput
								label={'Kohteen numero'}
								field={'kohde_numero'}
								autocomplete={'off'}
								handleOnChange={handleOnChange}
								data={data}
							/>
						</div>
						<div className="input-wrapper">
							<CustomInput
								label={'Etusivun gridin otsikko'}
								field={'name'}
								autocomplete={'organization'}
								handleOnChange={(e) => {
									handleOnChange(e);
								}}
								data={data}
							/>
						</div>
					</div>

					{isDestination(data) && (
						<div className="input-wrapper">
							<CustomSelect
								label={'Asiakkuuspaketti?'}
								field={'clientpackage_id'}
								handleOnChange={handleOnChange}
								data={data}
								options={packageOptions}
								allowDeselect={true}
							/>
						</div>
					)}

					{isDestination(data) && <div className="input-wrapper">
						<CustomInput
							label={'Virallinen/Yrityksen nimi'}
							placeholder="Yrityksen tai kohteen nimi (Esim. Googlessa)"
							field={'company_name'}
							handleOnChange={handleOnChange}
							data={data}
						/>
					</div>}

					{isDestination(data) && (
						<>
							<div className="input-wrapper">
								<CustomInput
									label={'Osoite / Slug'}
									field={'slug'}
									handleOnChange={handleOnChange}
									data={data}
								/>
							</div>
							<p style={{ marginBottom: 0 }}>
								Lopullinen osoite (slug) muodostetaan kohdekortin Etusivun
								gridin otsikosta jos kentän jättää tyhjäksi. <br />
								Esim: https://100syyta.fi/kohde/{data.slug ?? 'kohteen-slug'}
							</p>
						</>
					)}
				</fieldset>

				<fieldset>
					<div className="input-wrapper">
						<CustomInput
							label={'Kohdekortin otsikko'}
							field={'alt_title'}
							autocomplete={'organization'}
							handleOnChange={handleOnChange}
							data={data}
						/>
					</div>

					<div className="input-wrapper">
						<CustomRichTextArea
							label={'Yleinen artikkeliteksti'}
							field={'description'}
							handleOnChange={handleOnCKEditorChange}
							data={data}
						/>
					</div>

					<div className="input-wrapper">
						<CustomInput
							label={'Metakuvausteksti'}
							field={'meta_description'}
							placeholder={
								'Esimerkkiteksti: Maauimalamme on virkistävä keidas keskustassa. Sen isossa 50 metrin altaassa on tilaa niin uimareille, vesijuoksijoille, uimahyppääjille kuin muuten vaan pulikoijillekin. Pikkuväelle on tarjolla oma lasten allas. Aurinkoa mahtuu ottamaan laajoilla auringonottotasanteilla ja nurmialueilla.'
							}
							handleOnChange={handleOnChange}
							data={data}
							useTextarea={true}
						/>
					</div>
				</fieldset>
			</div>
		</>
	);
};

export const ViewAdditional = ({
	data,
	handleOnChange,
	handleOnCustomChange,
	handleOnCKEditorChange,
	sendFile,
	apiCall,
}) => {
	const template = [];
	return (
		<>
			<a id="lisa"></a>
			<div>
				<h2>Lisäsisältö</h2>
				<p>
					{/* Alla olevat tiedot on haettu Google My Business -palvelusta ja
					käytämme näitä tietoja 100 syytä -sivustolla ohjaamaan kuluttajaa
					palvelujenne pariin. */}
				</p>
				<SectionEditor
					field={'content_json'}
					data={data}
					handleOnChange={handleOnCustomChange}
					// CustomInput={CustomInput}
					// CustomRichTextArea={CustomRichTextArea}
					// CustomSelect={CustomSelect}
					// CustomPictureSelector={CustomPictureSelector}
					sendFile={sendFile}
					apiCall={apiCall}
					template={template}
				/>
			</div>
		</>
	);
};

export const ViewContact = ({ data, handleOnChange, handleSwitch }) => {
	return (
		<>
			<a id="yhteystiedot"></a>
			<div>
				<h2>Yhteystiedot</h2>
				<div className="input-wrapper">
					<CustomInput
						label={'Puhelinnumero'}
						field={'phone'}
						autocomplete={'tel'}
						handleOnChange={handleOnChange}
						data={data}
					/>
				</div>
				{/* <div className="input-wrapper">
					<CustomInput
						label={'www-osoite'}
						placeholder={'esim: https://100syyta.fi'}
						field={'www'}
						autocomplete={'url'}
						type={'url'}
						handleOnChange={handleOnChange}
						data={data}
					/>
				</div> */}
				<div className="input-wrapper">
					<CustomCheckbox
						label={'Aukioloajat'}
						subLabel={'Älä näytä aukioloaikoja 100 syytä -sivustolla'}
						field={'show_opentimes'}
						handleOnChange={handleSwitch}
						data={data}
					/>
				</div>
			</div>
		</>
	);
};

export const ViewLocation = ({
	data,
	handleSwitch,
	handleOnChange,
	handleOnChangeArray,
	handleOnChangeForCheckBoxes,
}) => {
	const { state } = useStore();
	const regions = state.regions.items;
	const companyregions = state.companyregions.items;

	return (
		<>
			<a id="lokaatio"></a>
			<div>
				<h2>Lokaatio</h2>
				<p>
					1. Valitse alta sopiva lokaatio johon kohde liittyy. Mikäli kyseessä
					on valtakunnallinen kohde, valitse kaikki alueet. <br />
					2. Klikkaa kartalta "sopiva" sijainti. Huomaa että kartta voi näkyä
					sivustolla pienempänä/erinäköisenä.
				</p>
				<fieldset>
					<div className="multi-input-wrapper">
						<div className="input-wrapper">
							{/* <CustomRadio
						label={'Alue'}
						field={'region_id'}
						handleOnChange={handleOnChange}
						data={data}
						options={regions}
					/> */}
							{isCompany(data) && (
								<CustomCheckboxes
									label={'Alue (valitse vähintään yksi)'}
									field={'companyregion'}
									handleOnChange={handleOnChangeForCheckBoxes}
									data={data}
									options={companyregions}
									className={'sparse'}
								/>
							)}
							{isDestination(data) && <CustomCheckboxes
								label={'Alue (valitse vähintään yksi)'}
								field={'region2021'}
								handleOnChange={handleOnChangeForCheckBoxes}
								data={data}
								options={regions}
								className={'sparse'}
							/>}
						</div>
						<div>
							<DotMap data={data} handleOnChange={handleOnChange} />
							{/* 
							<div className="input-wrapper">
								<CustomNestedInput
									label={'Sijainti - latitude'}
									parentKey={'map_marker'}
									field={'lat'}
									handleOnChange={handleOnChangeArray}
									data={data}
								/>
							</div>
							<div className="input-wrapper">
								<CustomNestedInput
									label={'Sijainti - longitude'}
									parentKey={'map_marker'}
									field={'lng'}
									handleOnChange={handleOnChangeArray}
									data={data}
								/>
							</div> */}
						</div>
					</div>
					<div className="input-wrapper">
						<CustomNestedInput
							label={'Paikka (vapaamuotoinen teksti)'}
							field={'title'}
							parentKey={'location'}
							autocomplete={'off'}
							handleOnChange={handleOnChangeArray}
							data={data}
						/>
					</div>
				</fieldset>
				<fieldset>
					<div className="grid">
						<div>
							<div className="input-wrapper">
								<CustomSwitch
									label={'Piilota osoite näkyvistä?'}
									field={'is_location_hidden'}
									handleOnChange={handleSwitch}
									data={data}
								/>
							</div>

							<div className="input-wrapper">
								<CustomNestedInput
									label={'Osoite'}
									parentKey={'location'}
									field={'address'}
									autocomplete={'street-address'}
									handleOnChange={handleOnChangeArray}
									data={data}
								/>
							</div>

							<div className="multi-input-wrapper address">
								<div className="input-wrapper">
									<CustomNestedInput
										label={'Postinumero'}
										parentKey={'location'}
										field={'postal_code'}
										autocomplete={'postal-code'}
										handleOnChange={handleOnChangeArray}
										data={data}
									/>
								</div>
								<div className="input-wrapper">
									<CustomNestedInput
										label={'Postitoimipaikka'}
										parentKey={'location'}
										field={'city'}
										autocomplete={'address-level2'}
										handleOnChange={handleOnChangeArray}
										data={data}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="multi-input-wrapper">
						<div className="input-wrapper">
							<CustomNestedInput
								label={'Sijainti - latitude'}
								parentKey={'location'}
								field={'lat'}
								handleOnChange={handleOnChangeArray}
								data={data}
							/>
						</div>
						<div className="input-wrapper">
							<CustomNestedInput
								label={'Sijainti - longitude'}
								parentKey={'location'}
								field={'lng'}
								handleOnChange={handleOnChangeArray}
								data={data}
							/>
						</div>
					</div>
				</fieldset>
				<div className="map">
					<GoogleMapEmbed
						placeId={data.googleId}
						lat={data?.location && (data?.location[0]?.lat ?? 0)}
						lng={data?.location && (data?.location[0]?.lng ?? 0)}
					/>
				</div>
			</div>
		</>
	);
};

export const DotMap = ({ data, handleOnChange }) => {
	// const [percentages, setPercentages] = useState({ x: 0, y: 0 });

	const { map_marker } = data;

	const onClick = (e) => {
		const rect = e.currentTarget.getBoundingClientRect();

		const diffs = {
			x: e.clientX - rect.x,
			y: e.clientY - rect.y,
		};
		const p = {
			x: (diffs.x / rect.width) * 100,
			y: (diffs.y / rect.height) * 100,
		};

		// console.log(p);
		// setPercentages(p);
		const newEvent = { target: { name: 'map_marker', value: p } };
		handleOnChange(newEvent);
	};

	const clearPos = () => {
		const newEvent = { target: { name: 'map_marker', value: {} } };
		handleOnChange(newEvent);
	};

	return (
		<div className="dot-map-holder">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				width="200"
				height="320"
				viewBox="0 0 100 160"
				onClick={onClick}
			>
				<defs>
					<style>{`.a,.e{fill:none;}.a{clip-rule:evenodd;}.b{fill:#323d85;}.b,.f{fill-rule:evenodd;}.c{clip-path:url(#a);}.d,.f{fill:#262b71;opacity:0.25;}.e{stroke:#262b71;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.5px;}.f{isolation:isolate;}`}</style>
					<clipPath id="a">
						<path
							className="a"
							d="M55.13,55.37c0-3.77-2.46-7.51-4.15-10.77-1-1.91-2.15-3.58-2-5.82.17-2.79,2.34-4.58,3.09-7.1,1.35-4.64-4.18-6-6.13-8.91a8.19,8.19,0,0,1-.53-5.21c.08-.92.31-1.74.31-2.64,0-.24-1.22-.17-1.34-.45.43.92,2.27-4.55,2.31-4.65.59-2.14,1.86-3.48.17-5.46-.86-1-2-.74-3-1.47S42.69,1.22,41.8.66c-2.36-1.49-1.73-.08-4.21.56-6.24-.89-4.9,9.62-6,12.78-.38,1.13-2.84,5.21-3.92,5.44s-3-.83-3.87-1.14c-5.31-1.81-7.39-8.31-11.75-9A6.27,6.27,0,0,0,8.5,11.64c-.52,1.93,1.82,3.7,3,4.86,3.6,3.47,8.68,4.72,9.19,10.33a45.21,45.21,0,0,0,3,14.77l.14-.16c-1.25.62-2.08,5.37-2,6.34a19,19,0,0,0,1.88,4.74c1,2.41,1.89,2.66,4.06,4.24s1.94,1.87,2.51,4.72v.09c-.71.36.12,2.45,0,2.69s-.93.43-1.19.73a1.23,1.23,0,0,0-2,.33A8,8,0,0,0,26.06,68c-.43,1-.83,2.12-1.35,3.13a42.55,42.55,0,0,1-6.81,8.27,46.72,46.72,0,0,0-5.32,5.75c1.38,2.1-1.74,1.73-2.28,2.06-1.26.8-1.16,1-2,2.21-1.51,2.27-2.57,3.52-2.4,6.44.14,2.29-.12,4.76.12,7.09.35,3.31,1.74,4.89.78,8.38-.57,2.61-2.44,7.28-.67,9.82.93,1.37,2.77,1.73,4.09,2.76.54.33,1.08.67,1.6,1a1.92,1.92,0,0,1-.1,2.31c.19.66.26,1.41.9,1.74s2.13-.37,2.88-.28c1.58.19,2.17,1.2,4,.83h-.33c4.27.83,11.09-2.17,15-3.84a50.46,50.46,0,0,1,6.62-2.43c.59-.17,1-.8,1.73-.9s1.44.52,2.14.35,1.73-1.2,2.08-1.2c2.72-2.19,4.51-6,6.82-8.69a56.22,56.22,0,0,0,5.92-8.12c1.84-3.06,3.78-6.1,5.52-9.22A10.06,10.06,0,0,0,66.74,91a17.49,17.49,0,0,0-.33-2.27h-.17c-1.53-1-2.38-2.76-3.92-3.91-1.18-.89-4.29-2-4.78-3.58s.61-2.08,1.22-3.47c1.51-3.47,0-3.32-1.74-5.64-1.39-1.83-.22-2.42-.74-4.31s-2.26-2.4-2.61-4.43c0-.23.87-.87.84-1.37s-.92-.73-1-1.48c-.21-1.73.16-4.77,1.61-5.5"
						/>
					</clipPath>
					<symbol id="b" viewBox="0 0 71.74 139.66">
						<g className="c">
							<rect className="d" x="0.39" width="71.35" height="139.66" />
						</g>
						<path className="e" d="M49.22,87m-7.9,7.9" />
						<path
							className="f"
							d="M4.8,128.54c-1.2-1-2.4-2-3.6-2-.8,0-1.2.34-1.2,1q0,4,3.6,4C7.2,131.54,6,129.54,4.8,128.54Z"
						/>
					</symbol>
					<symbol id="c" viewBox="0 0 17 17">
						<polygon
							className="b"
							points="17 13.73 11.77 8.5 17 3.27 13.73 0 8.5 5.23 3.27 0 0 3.27 5.23 8.5 0 13.73 3.27 17 8.5 11.77 13.73 17 17 13.73"
						/>
					</symbol>
				</defs>
				<title>kohde</title>
				<use
					width="71.74"
					height="139.66"
					transform="translate(16.86 12.63) rotate(-0.08)"
					xlinkHref="#b"
				/>
				{map_marker && map_marker.x && map_marker.y && (
					<use
						width="17"
						height="17"
						transform="translate(-8 -8)"
						x={`${map_marker.x}%`}
						y={`${map_marker.y}%`}
						xlinkHref="#c"
					/>
				)}
			</svg>
			{map_marker && map_marker.x && map_marker.y && (
				<button className="secondary" onClick={clearPos}>
					Poista merkintä
				</button>
			)}
		</div>
	);
};

export const ViewImages = ({ data, handleOnChange, sendFile, isUploading }) => {
	const fileSelectorRef1 = React.createRef();
	const fileSelectorRef2 = React.createRef();
	const fileSelectorRef3 = React.createRef();
	const fileSelectorRef4 = React.createRef();
	const fileSelectorRef5 = React.createRef();
	return (
		<>
			<a id="media"></a>
			<div>
				<h2>Päämedia</h2>
				<p>
					Lataa kohteelle vähintään jakokuva. Kuva tarvitaan silloinkin kun
					käytössä on video.
				</p>

				<fieldset>
					<div className={`input-wrapper`}>
						<CustomInput
							label={'Alt -kuvaus kuville tai videolle'}
							placeholder={''}
							field={'media_alt'}
							handleOnChange={handleOnChange}
							data={data}
						/>
					</div>

					{/* <legend>Kuvat</legend> */}
					<div className={`multi-input-wrapper media`}>
						<div className={`input-wrapper`}>
							<label className="label">
								<span>Vaaka(jako)kuva: </span>

								<CustomPictureSelector
									data={data}
									field={'thumb_id'}
									handleOnChange={sendFile}
									fileSelectorRef={fileSelectorRef1}
									autocomplete={'photo'}
									isRequired={false}
									isUploading={isUploading}
									className={'share'}
								/>
							</label>
						</div>
						<div className={`input-wrapper`}>
							<label className="label">
								<span>Neliö -kuva: </span>

								<CustomPictureSelector
									data={data}
									field={'square_img_id'}
									handleOnChange={sendFile}
									fileSelectorRef={fileSelectorRef2}
									autocomplete={'photo'}
									isRequired={false}
									isUploading={isUploading}
								/>
							</label>
						</div>
						<div className={`input-wrapper`}>
							<label className="label">
								<span>Pysty -kuva: </span>

								<CustomPictureSelector
									data={data}
									field={'portrait_img_id'}
									handleOnChange={sendFile}
									fileSelectorRef={fileSelectorRef3}
									autocomplete={'photo'}
									isRequired={false}
									isUploading={isUploading}
									className={'portrait'}
								/>
							</label>
						</div>
					</div>
				</fieldset>
				<fieldset>
					{/* <legend>Videot</legend> */}
					<p>
						Syötä tähän kohtaan vain suoria Distribution -linkkejä vimeosta,
						pelkkä vimeon playerin osoite ei riitä.
					</p>
					<div className={`multi-input-wrapper media`}>
						<div className={`input-wrapper`}>
							<div>
								<CustomInput
									label={'Video (oletus)'}
									placeholder={'https://example.com/example.mp4'}
									field={'video_url'}
									autocomplete={'url'}
									type={'url'}
									handleOnChange={handleOnChange}
									data={data}
								/>

								{data.video_url && (
									<video
										controls
										muted
										src={data.video_url}
										className="video-preview"
									></video>
								)}
							</div>
							{data.video_url && (
								<div>
									<label className="label">
										<span>Video poster-kuva: </span>

										<CustomPictureSelector
											data={data}
											field={'video_poster_id'}
											handleOnChange={sendFile}
											fileSelectorRef={fileSelectorRef4}
											autocomplete={'photo'}
											isRequired={false}
											isUploading={isUploading}
											className={'share'}
										/>
									</label>
								</div>
							)}
						</div>

						<div className={`input-wrapper`}>
							<div>
								<CustomInput
									label={'Video (pysty)'}
									placeholder={'https://example.com/example.mp4'}
									field={'video_portrait_url'}
									autocomplete={'url'}
									type={'url'}
									handleOnChange={handleOnChange}
									data={data}
								/>
								{data.video_portrait_url && (
									<video
										controls
										muted
										src={data.video_portrait_url}
										className="video-preview"
									></video>
								)}
							</div>
							{data.video_portrait_url && (
								<div>
									<label className="label">
										<span>Video poster-kuva (pysty): </span>

										<CustomPictureSelector
											data={data}
											field={'video_poster_portrait_id'}
											handleOnChange={sendFile}
											fileSelectorRef={fileSelectorRef5}
											autocomplete={'photo'}
											isRequired={false}
											isUploading={isUploading}
											className={'share portrait'}
										/>
									</label>
								</div>
							)}
						</div>

						<div className={`input-wrapper`}>
							<div>
								<CustomInput
									label={'Video (pysty, low-res)'}
									placeholder={'https://example.com/example.mp4'}
									field={'video_small_url'}
									autocomplete={'url'}
									type={'url'}
									handleOnChange={handleOnChange}
									data={data}
								/>
								{data.video_small_url && (
									<video
										controls
										muted
										src={data.video_small_url}
										className="video-preview"
									></video>
								)}
							</div>
						</div>						
					</div>
				</fieldset>

	
			</div>
		</>
	);
};

export const ViewContactInfo = ({
	data,
	handleOnChange,
	handleOnChangeArray,
	formRef,
}) => {
	return (
		<div>
			<h2>Yhteyshenkilön tiedot</h2>
			<p>
				Yhteyshenkilön tietoja käytetään ainoastaan mahdollista yhteydenpitoa
				varten koskien tässä lomakkeessa annettuja tietoja yrityksestä. Tietoja
				ei anneta eteenpäin kolmansille osapuolille tai käytetä
				markkinointitarkoituksessa.
			</p>
			<div className="grid-2 grid-narrow">
				<div className="input-wrapper">
					<CustomInput
						label={'Etunimi'}
						field={'contact_first_name'}
						autocomplete={'given-name'}
						handleOnChange={handleOnChange}
						data={data}
					/>
				</div>
				<div className="input-wrapper">
					<CustomInput
						label={'Sukunimi'}
						field={'contact_last_name'}
						autocomplete={'family-name'}
						handleOnChange={handleOnChange}
						data={data}
					/>
				</div>

				<div className="input-wrapper">
					<CustomInput
						label={'Puhelinnumero'}
						field={'contact_phone'}
						autocomplete={'tel'}
						type={'tel'}
						handleOnChange={handleOnChange}
						data={data}
					/>
				</div>
				<div className="input-wrapper">
					<CustomInput
						label={'Sähköposti'}
						field={'contact_email'}
						autocomplete={'email'}
						type={'email'}
						handleOnChange={handleOnChange}
						data={data}
					/>
				</div>
			</div>
		</div>
	);
};

export const ViewCategories = ({
	data,
	handleSwitch,
	// handleOnChange,
	handleOnChangeForCheckBoxes,
	// sendFile,
	// formRef,
	customErrors,
	// isValid,
}) => {
	const { state } = useStore();
	const categories = state.categories.items;
	const companycategories = state.companycategories.items;

	return (
		<>
			<a id="kategoriat"></a>
			<div>
				<h2>Kategoriat</h2>
				<fieldset>
					<div className="subsection ">
						<div className={`input-wrapper `}>
							<p style={{ marginLeft: 0 }}>
								Kategoriat määrittävät mille teemasivuille kohde tuodaan
								näkyviin.
							</p>
							{isCompany(data) && (
								<div
									className={`${
										customErrors.findIndex((i) => {
											return i.field === 'companycategory';
										}) !== -1
											? 'error'
											: ''
									}`}
								>
									<CustomCheckboxes
										label={'Kategoria (valitse vähintään yksi)'}
										field={'companycategory'}
										handleOnChange={handleOnChangeForCheckBoxes}
										data={data}
										options={companycategories}
									/>
								</div>
							)}
							{isDestination(data) && (
								<div
									className={`${
										customErrors.findIndex((i) => {
											return i.field === 'category';
										}) !== -1
											? 'error'
											: ''
									}`}
								>
									<CustomCheckboxes
										label={'Kategoria (valitse vähintään yksi)'}
										field={'category'}
										handleOnChange={handleOnChangeForCheckBoxes}
										data={data}
										options={categories}
									/>
								</div>
							)}
						</div>
					</div>

					<div className="subsection ">
						<div className={`input-wrapper `}>
							<br />
							<CustomSwitch
								label={'Piilevä helmi?'}
								field={'is_hidden_gem'}
								handleOnChange={handleSwitch}
								data={data}
							/>
						</div>
					</div>
				</fieldset>
			</div>
		</>
	);
};

export const ViewInstagram = ({ data, handleOnChange, formRef }) => {
	return (
		<>
			<a id="insta"></a>
			<div className="step-instagram">
				<h2>Instagram</h2>

				<p>
					<strong>Ohjeet kohteille:</strong>
				</p>
				<p>
					Palvelun kautta tuomme sivustolle mukana olevien kohteiden
					Instagram-sisältöjä. Näin voimme varmistaa, että sivuston sisältö on
					aina tuoretta ja kohteet voivat itse hallita, mitä kuvia sivustolla
					näkyy.
				</p>
				<p>
					Jakamalla kuvan Instagram-tilillään käyttäen
					#100syytämatkaillaSuomessa -tunnistetta, yritys saa nostettua kuvan
					sivustolle. Ilman yo. tunnistetta Instagramissa jaetut kuvat eivät
					nouse 100 syytä -sivustolle, joten yrityksellä säilyy mahdollisuus
					itse valita yritysesittelyyn liitettävät sisällöt. Näytämme sivustolla
					aina viisi uusinta kuvaa.
				</p>
				<p>
					Valitse Instagramiin laadukkaita fiilis- ja inspiraatiokuvia, jotka
					kuvaavat yrityksesi aktiviteetteja ja palveluja. Älä mielellään lisää
					kuvien päälle tekstejä tai päivämääriä. Hyödynnä Instagram postausten
					jakotekstit, jos haluat tuoda esiin esimerkiksi yrityksesi tarjouksia
					sivustolla.
				</p>
				<p>
					Kuvat voidaan nostaa Instagramista sivustolle ainoastaan, jos ne on
					julkaistu julkisessa profiilissa. K18 luokitellut profiilit (esim.
					alkoholibrändit) eivät voi käyttää Instagramia tässä tarkoituksessa.
				</p>
				<p>
					<strong>
						Kuvassa pitää julkaistaessa olla mukana #100syytämatkaillaSuomessa
						-hashtag, jotta se tulee näkyviin. Hashtagin lisääminen jälkikäteen
						tai kommenttiin ei vaikuta näkyvyyteen.
					</strong>
				</p>

				<p>Mikäli kohteella on useampi IG -tili, erottele ne pilkulla</p>
				{/* <p>
				Jakamalla Instagram-tilin nimen yritys antaa ylläpitäjälle oikeuden
				julkaista sisältöjä 100 syytä matkailla Suomessa -sivustolla.
			</p> */}
				<fieldset>
					<div className="grid-narrow">
						<div className="pad-section">
							<div className="input-wrapper">
								<CustomInput
									label={
										'Yrityksen Instagram -tilin nimi/nimet ilman @ -merkkiä, pilkulla eroitettuna'
									}
									placeholder={'tilinnimi'}
									autocomplete={'username'}
									field={'ig_account'}
									handleOnChange={handleOnChange}
									data={data}
								/>
							</div>
						</div>
						{isDestination(data) && (
							<div className="row">
								<InstagramFeed
									account={data.ig_account}
									// setAsMainImageCallback={(response) => {
									// 	if (
									// 		response.status === 200 &&
									// 		response.data &&
									// 		response.data.data
									// 	) {
									// 		setPlaceData({
									// 			...data,
									// 			thumb_id: response.data.data.id,
									// 		});
									// 	}
									// }}
								/>
							</div>
						)}
					</div>
				</fieldset>
			</div>
		</>
	);
};

export const ViewLinks = ({ data, handleOnChange, formRef, handleSwitch }) => {
	const { state } = useStore();

	const primaryUrl = state.common.primaryUrlStates.map((item) => {
		item = { ...item, disabled: false };
		if (item.id === 'shop') {
			item.disabled = data.www_shop ? false : true;
		}
		if (item.id === 'fb') {
			item.disabled = data.fb_account ? false : true;
		}

		return item;
	});

	return (
		<>
			<a id="linkit"></a>
			<div>
				<h2>Ohjauslinkitykset</h2>
				<p>
					Ohjauslinkkien tarkoituksena on ohjata asiakas tutustumaan lisää
					yrityksen tarjontaan yrityksen omilla nettisivuilla, verkkokaupassa
					tai Facebook-sivuilla.
				</p>
				<p>
					Yritys voi halutessaan jakaa esittelyssään joko kaikki tai osan näistä
					kanavista ja valita näistä ensisijaisen ohjausosoitteen.
				</p>
				<fieldset>
					<div className="grid-narrow">
						<div className="subsection">
							<div className="input-wrapper test-link">
								<CustomInput
									label={'WWW-sivut'}
									placeholder={'www-osoite, esim: https://100syyta.fi'}
									field={'www'}
									autocomplete={'url'}
									type={'url'}
									handleOnChange={handleOnChange}
									data={data}
								/>
								{data.www && (
									<a
										href={urlWithHttp(data.www)}
										target="_blank"
										rel="noopener noreferrer"
										className="button secondary"
									>
										Testaa linkkiä »
									</a>
								)}
							</div>
							<div className="input-wrapper test-link">
								<CustomInput
									label={'Verkkokauppa / varaussivu / muu'}
									placeholder={'esim: https://100syyta.fi/vinkkipankki'}
									field={'www_shop'}
									autocomplete={'url'}
									type={'url'}
									handleOnChange={handleOnChange}
									data={data}
								/>
								{data.www_shop && (
									<a
										href={urlWithHttp(data.www_shop)}
										target="_blank"
										rel="noopener noreferrer"
										className="button secondary"
									>
										Testaa linkkiä »
									</a>
								)}
							</div>
							<div className="input-wrapper test-link">
								<CustomInput
									label={'Yrityksen Facebook -sivu'}
									placeholder={
										'esim: https://www.facebook.com/100syytamatkaillasuomessa'
									}
									field={'fb_account'}
									autocomplete={'url'}
									type={'url'}
									handleOnChange={handleOnChange}
									data={data}
								/>
								{data.fb_account && (
									<a
										href={urlWithHttp(data.fb_account)}
										target="_blank"
										rel="noopener noreferrer"
										className="button secondary"
									>
										Testaa linkkiä »
									</a>
								)}
							</div>
{/* 
							<div className="input-wrapper">
								<CustomInput
									label={'Puhelinnumero'}
									field={'phone'}
									autocomplete={'tel'}
									handleOnChange={handleOnChange}
									data={data}
								/>
							</div> */}

							{/* <fieldset className="google" id="google"> */}
							{/* <legend>Google</legend> */}
							{/* <div className="row">
								<div className="col">
									<CustomInput
										label={'Google ID'}
										field={'googleId'}
										handleOnChange={handleOnChange}
										disabled={true}
										data={data}
									/>
									<CustomSwitch
										label={'Näytä aukioloajat 100 Syytä -sivustolla'}
										field={'show_opentimes'}
										handleOnChange={handleSwitch}
										data={data}
									/>
								</div>
							</div> */}
							{/* </fieldset> */}
							<div className="input-wrapper">
								<CustomRadio
									label={'Valitse ensisijainen ohjauslinkki'}
									field={'primary_www'}
									handleOnChange={handleOnChange}
									data={data}
									options={primaryUrl}
									cast={'string'}
									className="full-wide"
								/>
							</div>
						</div>

						{/* <div className="subsection">
					<h3>Turvallisuusohjeet</h3>
					<p>
						Mikäli yrityksellä on erillisiä turvallisuuohjeita Covid-19
						-tilanteeseen liittyen jaettavaksi asiakkaille, voidaan esittelyssä
						jakaa linkki myös siihen.
					</p>
					<div className="input-wrapper">
						<CustomInput
							label={
								'Yrityksen turvallisuusohjeistus Covid-19 tilanteeseen liittyen'
							}
							placeholder={'www-osoite, esim: https://100syyta.fi'}
							autocomplete={'url'}
							field={'www_safety'}
							handleOnChange={handleOnChange}
							data={data}
						/>
					</div>
				</div> */}
					</div>
				</fieldset>
			</div>
		</>
	);
};

export const ViewNote = ({ data, handleOnChange, formRef }) => {
	return (
		<div>
			<h2>Viesti ylläpidolle</h2>
			<p>
				Jäikö jokin mietityttämään? Kohtasitko ongelmia lomakkeen kanssa? Jätä
				viesti ylläpidolle. Voit ottaa yhteyttä tukeemme myös sähköpostilla
				(100syyta@dqcomms.com).
				{/* tai chatilla. */}
			</p>
			<div className="grid-narrow">
				<div className="input-wrapper">
					<CustomInput
						label={'Viesti ylläpidolle'}
						field={'message'}
						handleOnChange={handleOnChange}
						data={data}
						placeholder={'Viesti'}
						useTextarea={true}
					/>
				</div>
			</div>
		</div>
	);
};

// export const ViewSummary = ({
// 	data,
// 	handleOnChange,
// 	formRef,
// 	setStep,
// 	step,
// }) => {
// 	const errors = getErrors(data);

// 	const sublabel = (
// 		<>
// 			Vakuutan alla olevat tiedot oikeiksi ja olen lukenut{' '}
// 			<a href="/kayttoehdot" target="_blank">
// 				käyttöehdot
// 			</a>{' '}
// 			ja{' '}
// 			<a href="/rekisteriseloste" target="_blank">
// 				rekisteriselosteen
// 			</a>
// 		</>
// 	);

// 	return (
// 		<div>
// 			<h2>Yhteenveto</h2>
// 			<div className="grid-narrow">
// 				{/* {errors && errors.length > 0 && (
// 					<div className="form-errors">
// 						<p>
// 							Tarkista seuraavat kentät:{' '}
// 							{errors.map((i) => {
// 								return (
// 									<a
// 										onClick={(e) => {
// 											e.preventDefault();
// 											e.stopPropagation();
// 											goToField(i);
// 										}}
// 										key={`fi-${i}`}
// 									>
// 										{translateError(i)},{' '}
// 									</a>
// 								);
// 							})}
// 						</p>
// 					</div>
// 				)} */}

// 				<div className="confirm-dialog">
// 					<div className="input-wrapper">
// 						<CustomCheckbox
// 							subLabel={sublabel}
// 							field={'confirm'}
// 							handleOnChange={handleOnChange}
// 							data={data}
// 						/>
// 						<button type="submit" className="next">
// 							Tallenna tiedot ›
// 						</button>{' '}
// 					</div>
// 				</div>
// 				<div className="input-wrapper">
// 					<p style={{ textAlign: 'center' }}>
// 						<a onClick={() => setStep(step - 1)} className="back">
// 							‹ Palaa takaisin muokkaamaan tietoja
// 						</a>
// 					</p>
// 				</div>

// 				<Summary data={data} />
// 			</div>
// 		</div>
// 	);
// };

// export const Summary = ({ data }) => {
// 	const { state, dispatch } = useStore();
// 	// const primaryUrl = state.common.primaryUrlStates;
// 	const primaryUrlOption =
// 		state.common.primaryUrlStates.find((i) => i.id === data.primary_www) ||
// 		false;
// 	const primaryUrl = primaryUrlOption ? primaryUrlOption.name : '---';
// 	return (
// 		<div className="summary">
// 			<h3>Yritystiedot</h3>
// 			<table>
// 				<tbody>
// 					<tr>
// 						<th>Yrityksen nimi</th>
// 						<td>{data.name}</td>
// 					</tr>
// 					<tr>
// 						<th>Katuosoite</th>
// 						<td>{data?.location[0]?.address}</td>
// 					</tr>
// 					<tr>
// 						<th>Postinumero</th>
// 						<td>{data?.location[0]?.postal_code}</td>
// 					</tr>
// 					<tr>
// 						<th>Toimipaikka:</th>
// 						<td>{data?.location[0]?.address}</td>
// 					</tr>
// 					<tr>
// 						<th>Sijainti:</th>
// 						<td>
// 							{data?.location[0]?.lat} {data?.location[0]?.lng}
// 						</td>
// 					</tr>
// 					<tr>
// 						<th>Puhelinnumero:</th>
// 						<td>{data.phone}</td>
// 					</tr>
// 					<tr>
// 						<th>www-osoite:</th>
// 						<td>{data.www}</td>
// 					</tr>
// 				</tbody>
// 			</table>

// 			<h3>Yhteyshenkilön tiedot</h3>
// 			<table>
// 				<tbody>
// 					<tr>
// 						<th>Nimi</th>
// 						<td>
// 							{data.contact_first_name} {data.contact_last_name}
// 						</td>
// 					</tr>
// 					<tr>
// 						<th>Sähköposti</th>
// 						<td>{data.contact_email}</td>
// 					</tr>
// 					<tr>
// 						<th>Puhelinnumero</th>
// 						<td>{data.contact_phone}</td>
// 					</tr>
// 				</tbody>
// 			</table>

// 			<h3>Yrityksen lisätiedot</h3>
// 			<table>
// 				<tbody>
// 					<tr>
// 						<th>Henkilöstömäärä</th>
// 						<td>{data.company_size === 1 ? '1-5' : '5+'}</td>
// 					</tr>
// 					<tr>
// 						<th>Y-tunnus</th>
// 						<td>{data.company_vatnumber}</td>
// 					</tr>
// 					<tr>
// 						<th>Toimiala</th>
// 						<td>{data.company_industry}</td>
// 					</tr>
// 					<tr>
// 						<th>Yrityksen sijainti</th>
// 						<td>{data?.region?.name ?? ''}</td>
// 					</tr>
// 					<tr>
// 						<th>Kuvaus</th>
// 						<td>{data.description}</td>
// 					</tr>
// 					{/* <tr>
// 						<th>Kuva</th>
// 						<td>{data.image}</td>
// 					</tr> */}
// 					<tr>
// 						<th>Kategoriat</th>
// 						<td>{data.category.map((item) => item.name).join(', ')}</td>
// 					</tr>
// 				</tbody>
// 			</table>

// 			<h3>Instagram</h3>
// 			<table>
// 				<tbody>
// 					<tr>
// 						<th>Tilin nimi</th>
// 						<td>{data.ig_account}</td>
// 					</tr>
// 				</tbody>
// 			</table>

// 			<h3>Ohjauslinkit</h3>
// 			<table>
// 				<tbody>
// 					<tr>
// 						<th>WWW -sivusto</th>
// 						<td>{data.www}</td>
// 					</tr>
// 					<tr>
// 						<th>Verkkokauppa / muu </th>
// 						<td>{data.www_shop}</td>
// 					</tr>
// 					<tr>
// 						<th>Facebook -sivu</th>
// 						<td>{data.fb_account}</td>
// 					</tr>
// 					<tr>
// 						<th>Ensisijainen ohjauslinkki</th>
// 						<td>{primaryUrl}</td>
// 					</tr>
// 					<tr>
// 						<th>Turvallisuusohjeistus</th>
// 						<td>{data.www_safety}</td>
// 					</tr>
// 				</tbody>
// 			</table>
// 		</div>
// 	);
// };

export const ViewStatus = ({ data, handleOnChange, formRef, handleSwitch }) => {
	// const { state, dispatch } = useStore();
	const validateErrors = validateKohde(data);

	return (
		<div>
			<h2>Status</h2>
			<div className="grid-2">
				<div>
					<div className="input-wrapper">
						<CustomSwitch
							label={'Sisällönsyöttö valmis?'}
							field={'is_ok'}
							handleOnChange={handleSwitch}
							data={data}
						/>
					</div>
					<div className="input-wrapper">
						<CustomSwitch
							label={'Lähetetty hyväksyntään?'}
							field={'is_sent'}
							handleOnChange={handleSwitch}
							data={data}
						/>
					</div>
					<div className="input-wrapper">
						<CustomSwitch
							label={'Julkaistu?'}
							field={'is_published'}
							handleOnChange={handleSwitch}
							data={data}
						/>
					</div>
				</div>
				<div>
					{validateErrors &&
						validateErrors.map((i) => {
							return <p key={i.type}>⚠️ {i.label}</p>;
						})}
				</div>
			</div>
		</div>
	);
};


export const ViewCompanyStatus = ({
	data,
	handleOnChange,
	formRef,
	handleSwitch,
}) => {
	const { state } = useStore();
	const kohdekortit = [
		{ name: 'Ei 100 Syytä kohde', id: '' },
		...state.destinations.items,
	];
	return (
		<div>
			<h2>Status</h2>
			<div className="grid-narrow">
				<div className="input-wrapper">
					<CustomSwitch
						label={'Tiedot vahvistettu?'}
						field={'status'}
						handleOnChange={handleSwitch}
						data={data}
					/>
				</div>
				<div className="input-wrapper">
					<CustomSwitch
						label={'Maksettu?'}
						field={'is_paid'}
						disabled={parseInt(data.status) !== 1}
						handleOnChange={handleSwitch}
						data={data}
					/>
				</div>
				<div className="input-wrapper">
					<CustomSwitch
						label={'Julkaistu?'}
						field={'is_published'}
						disabled={
							parseInt(data.is_published) !== 1 &&
							parseInt(data.is_paid) !== 1
						}
						handleOnChange={handleSwitch}
						data={data}
					/>
				</div>
				<div className="input-wrapper">
					{/* <CustomSwitch
						label={'100 Syytä -kohde?'}
						field={'is_100syyta'}
						handleOnChange={handleSwitch}
						data={data}
					/> */}
					<CustomSelect
						label={'100 Syytä kohde?'}
						field={'kohde_pageid'}
						handleOnChange={handleOnChange}
						data={data}
						options={kohdekortit}
					/>
				</div>
			</div>
		</div>
	);
};


/* ------------------------- */

export const PaymentButton = ({ payment }) => {

	// if (payment && payment.payment_form && payment.payment_form.paymentData) {
	// 	return (
	// 		<form method="POST" action={payment.payment_form.url}>
	// 			{Object.entries(payment.payment_form.paymentData).map(
	// 				([key, value]) => {
	// 					return (
	// 						<input
	// 							name={key}
	// 							type="hidden"
	// 							value={value !== null ? value : ''}
	// 							key={key}
	// 						/>
	// 					);
	// 				}
	// 			)}
	// 			<button type="submit">Siirry maksamaan ›</button>
	// 		</form>
	// 	);
	if (
		payment &&
		payment.payment_form &&
		payment.payment_form.error == null
	) {
		return (
			<>
				{payment.payment_form.href && (
					<a
						href={payment.payment_form.href}
						className="button"
						rel="noreferrer"
					>
						Siirry maksamaan ›
					</a>
				)}
				{/* <div className="payment-providers">
					{payment.payment_form.providers &&
						payment.payment_form.providers.map((a) => {
							return (
								<a href={a.url}>
									<img src={a.svg} alt={a.name} />
								</a>
							);
						})}
				</div> */}
			</>
		);
	} else {
		console.log(payment);
		return (
			<a
				href="mailto:100syyta@dqcomms.com"
				target="_blank"
				className="error"
				rel="noreferrer"
			>
				Maksua ei voitu luoda - ota yhteyttä
			</a>
		);
	}
};

export const ReceiptButton = ({ payment }) => {
	if (payment && payment.status === 'paid') {
		return (
			<a
				href={`${config.apiUrl}/invoices/${payment.order_no}`}
				type="submit"
				className="button"
			>
				Lataa kuitti ›
			</a>
		);
	} else {
		return (
			// <button onClick={() => window.Intercom('show')} className="error button">
			<button className="error button">
				Kuittia ei voitu ladata - ota yhteyttä
			</button>
		);
	}
};

export const ViewPayments = ({ data }) => {

	return (
		<div>
			<a id="maksut" />
			<h2>{data.is_paid ? 'Kiitos maksusta!' : 'Maksu'}</h2>
			<p>
				<strong>
					100 syytä matkailla Suomessa -kampanjan Vinkkipankki 100+ -palvelun
					osallistumismaksu
				</strong>
			</p>
			{/* <p>
				Maksun suuruus määräytyy henkilöstön määrän mukaan. Tiedot tulevat julki
				sivustolle, kun maksu on suoritettu ja ylläpito on tarkistanut
				lomakkeella jaetut tiedot.
				Maksulinkki lähetetään myös sähköpostitse
				yrityksen nimeämälle yhteyshenkilölle.
			</p> */}

			{!data.payment && <h3>Ei maksuja</h3>}

			{data.payment &&
				data.payment.map((payment) => {
					return (
						<div className="paymentInfo" key={payment.id}>
							<div className="panel">
								<h3>Maksutiedot</h3>
								<table>
									<tbody>
										<tr>
											<th>Tilausnumero:</th>
											<td>{payment.order_no}</td>
										</tr>
										<tr>
											<th>Tuote/Palvelu:</th>
											<td>{payment.product}</td>
										</tr>
										<tr>
											<th>Näkyvyys alkaa:</th>
											<td>
												Kun maksu on suoritettu ja ylläpito tarkistanut tiedot
											</td>
										</tr>
										{/* <tr>
											<th>Päättyy:</th>
											<td>31.10.2020</td>
										</tr> */}
										<tr>
											<th>Kokonaissumma:</th>
											<td>{payment.total} € (+ ALV 24%)</td>
										</tr>
										<tr>
											<th>Tila:</th>
											<td>
												{payment.status === 'paid'
													? 'Maksettu'
													: 'Odottaa suoritusta'}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							{/* <button onClick={payPayment(payment.id)}> */}
							{payment.status !== 'paid' && (
								<PaymentButton payment={payment}>
									Siirry maksamaan ›
								</PaymentButton>
							)}
							{payment.status === 'paid' && (
								<ReceiptButton payment={payment}>Lataa kuitti ›</ReceiptButton>
							)}
						</div>
					);
				})}
		</div>
	);
};




export const ViewBasicInfoWithMap = ({
	data,
	handleOnChange,
	handleSwitch,
	handleOnChangeArray
}) => {

	return (
		<div>
			<h2>Perustiedot</h2>
			<p>
				Alla olevat tiedot on haettu Google My Business -palvelusta ja käytämme
				näitä tietoja 100 syytä -sivustolla ohjaamaan kuluttajaa palvelujenne
				pariin.
			</p>
			<div className="split">
				<div className="left">
					<div className="input-wrapper">
						<CustomInput
							label={'Yrityksen nimi'}
							field={'name'}
							autocomplete={'organization'}
							handleOnChange={handleOnChange}
							data={data}
						/>
					</div>


					<div className="input-wrapper">
						<CustomNestedInput
							label={'Osoite'}
							parentKey={'location'}
							field={'address'}
							autocomplete={'street-address'}
							handleOnChange={handleOnChangeArray}
							data={data}
						/>
					</div>

					<div className="multi-input-wrapper address">
						<div className="input-wrapper">
							<CustomNestedInput
								label={'Postinumero'}
								parentKey={'location'}
								field={'postal_code'}
								autocomplete={'postal-code'}
								handleOnChange={handleOnChangeArray}
								data={data}
							/>
						</div>
						<div className="input-wrapper">
							<CustomNestedInput
								label={'Postitoimipaikka'}
								parentKey={'location'}
								field={'city'}
								autocomplete={'address-level2'}
								handleOnChange={handleOnChangeArray}
								data={data}
							/>
						</div>
					</div>

					<div className="multi-input-wrapper">
						<div className="input-wrapper">
							<CustomNestedInput
								label={'Sijainti - latitude'}
								parentKey={'location'}
								field={'lat'}
								handleOnChange={handleOnChangeArray}
								data={data}
							/>
						</div>
						<div className="input-wrapper">
							<CustomNestedInput
								label={'Sijainti - longitude'}
								parentKey={'location'}
								field={'lng'}
								handleOnChange={handleOnChangeArray}
								data={data}
							/>
						</div>
					</div>

					<div className="input-wrapper">
						<CustomInput
							label={'Puhelinnumero'}
							field={'phone'}
							autocomplete={'tel'}
							handleOnChange={handleOnChange}
							data={data}
						/>
					</div>

					<div className="input-wrapper test-link">
						<CustomInput
							label={'www-osoite'}
							placeholder={'esim: https://100syyta.fi'}
							field={'www'}
							autocomplete={'url'}
							type={'url'}
							handleOnChange={handleOnChange}
							data={data}
						/>

						{data.www && (
							<a
								href={urlWithHttp(data.www)}
								target="_blank"
								rel="noopener noreferrer"
								className="button secondary"
							>
								Testaa linkkiä »
							</a>
						)}
					</div>
					<div className="input-wrapper">
						<CustomCheckbox
							label={'Aukioloajat'}
							subLabel={'Älkää näyttäkö aukioloaikoja 100 syytä -sivustolla'}
							field={'show_opentimes'}
							handleOnChange={handleSwitch}
							data={data}
						/>
					</div>
				</div>
				<div className="map">
					<GoogleMapEmbed
						placeId={data.googleId}
						lat={data?.location && (data?.location[0]?.lat ?? 0)}
						lng={data?.location && (data?.location[0]?.lng ?? 0)}
					/>
				</div>
			</div>
		</div>
	);
};


export const ViewSummary = ({
	data,
	handleOnChange,
	formRef,
	setStep,
	step,
}) => {
	// eslint-disable-next-line no-unused-vars
	const errors = getErrors(data);

	const sublabel = (
		<>
			Vakuutan alla olevat tiedot oikeiksi ja olen lukenut{' '}
			<a href="/kayttoehdot" target="_blank">
				käyttöehdot
			</a>{' '}
			ja{' '}
			<a href="/rekisteriseloste" target="_blank">
				rekisteriselosteen
			</a>
		</>
	);

	return (
		<div>
			<h2>Yhteenveto</h2>
			<div className="grid-narrow">
				{/* {errors && errors.length > 0 && (
					<div className="form-errors">
						<p>
							Tarkista seuraavat kentät:{' '}
							{errors.map((i) => {
								return (
									<a
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											goToField(i);
										}}
										key={`fi-${i}`}
									>
										{translateError(i)},{' '}
									</a>
								);
							})}
						</p>
					</div>
				)} */}

				<div className="confirm-dialog">
					<div className="input-wrapper">
						<CustomCheckbox
							subLabel={sublabel}
							field={'confirm'}
							handleOnChange={handleOnChange}
							data={data}
						/>
						<button type="submit" className="next">
							Tallenna tiedot ›
						</button>{' '}
					</div>
				</div>
				<div className="input-wrapper">
					<p style={{ textAlign: 'center' }}>
						<a onClick={() => setStep(step - 1)} className="back">
							‹ Palaa takaisin muokkaamaan tietoja
						</a>
					</p>
				</div>

				<Summary data={data} />

				<div className="input-wrapper">
					<p style={{ textAlign: 'center' }}>
						<a onClick={() => setStep(step - 1)} className="back">
							‹ Palaa takaisin muokkaamaan tietoja
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export const Summary = ({ data }) => {
	const { state } = useStore();
	const primaryUrlOption =
		state.common.primaryUrlStates.find((i) => i.id === data.primary_www) ||
		false;
	const primaryUrl = primaryUrlOption ? primaryUrlOption.name : '---';

	return (
		<div className="summary">
			<h3>Viesti ylläpidolle:</h3>
			<table>
				<tbody>
					<tr>
						<th>Viesti</th>
						<td>{data.message}</td>
					</tr>
				</tbody>
			</table>
			<h3>Yritystiedot</h3>
			<table>
				<tbody>
					<tr>
						<th>Yrityksen nimi</th>
						<td>{data.name}</td>
					</tr>
					<tr>
						<th>Katuosoite</th>
						<td>{data?.location[0]?.address}</td>
					</tr>
					<tr>
						<th>Postinumero</th>
						<td>{data?.location[0]?.postal_code}</td>
					</tr>
					<tr>
						<th>Toimipaikka:</th>
						<td>{data?.location[0]?.address}</td>
					</tr>
					<tr>
						<th>Sijainti:</th>
						<td>
							{data?.location[0]?.lat}, {data?.location[0]?.lng}
						</td>
					</tr>
					<tr>
						<th>Puhelinnumero:</th>
						<td>{data.phone}</td>
					</tr>
					<tr>
						<th>www-osoite:</th>
						<td>{data.www}</td>
					</tr>
				</tbody>
			</table>

			<h3>Yhteyshenkilön tiedot</h3>
			<table>
				<tbody>
					<tr>
						<th>Nimi</th>
						<td>
							{data.contact_first_name} {data.contact_last_name}
						</td>
					</tr>
					<tr>
						<th>Sähköposti</th>
						<td>{data.contact_email}</td>
					</tr>
					<tr>
						<th>Puhelinnumero</th>
						<td>{data.contact_phone}</td>
					</tr>
				</tbody>
			</table>

			<h3>Yrityksen lisätiedot</h3>
			<table>
				<tbody>
					{/* <tr>
						<th>Henkilöstömäärä</th>
						<td>{data.company_size === 1 ? '1-5' : '5+'}</td>
					</tr> */}
					<tr>
						<th>Y-tunnus</th>
						<td>{data.company_vatnumber}</td>
					</tr>
					{/* <tr>
						<th>Toimiala</th>
						<td>{data.company_industry}</td>
					</tr> */}
					<tr>
						<th>Yrityksen sijainti</th>
						{/* <td>{data?.region?.name ?? ''}</td> */}
						<td>{data.companyregion.map((item) => item.name).join(', ')}</td>
					</tr>
					<tr>
						<th>Kuvaus</th>
						<td>{data.description}</td>
					</tr>
					{/* <tr>
						<th>Kuva</th>
						<td>{data.image}</td>
					</tr> */}
					<tr>
						<th>Kategoriat</th>
						<td>{data.companycategory.map((item) => item.name).join(', ')}</td>
					</tr>
				</tbody>
			</table>

			<h3>Instagram</h3>
			<table>
				<tbody>
					<tr>
						<th>Tilin nimi</th>
						<td>{data.ig_account}</td>
					</tr>
				</tbody>
			</table>

			<h3>Ohjauslinkit</h3>
			<table>
				<tbody>
					<tr>
						<th>WWW -sivusto</th>
						<td>{data.www}</td>
					</tr>
					<tr>
						<th>Verkkokauppa / muu </th>
						<td>{data.www_shop}</td>
					</tr>
					<tr>
						<th>Facebook -sivu</th>
						<td>{data.fb_account}</td>
					</tr>
					<tr>
						<th>Ensisijainen ohjauslinkki</th>
						<td>{primaryUrl}</td>
					</tr>
					{/* <tr>
						<th>Turvallisuusohjeistus</th>
						<td>{data.www_safety}</td>
					</tr> */}
				</tbody>
			</table>
		</div>
	);
};


export const ViewCompanyInfo = ({
	data,
	handleOnChange,
	handleOnChangeForCheckBoxes,
	sendFile,
	formRef,
	customErrors,
	isValid,
}) => {
	const { state } = useStore();
	const fileSelectorRef = React.createRef();
	const companyregions = state.companyregions.items;
	const companycategories = state.companycategories.items;
	// const packages = state.packages.items;
	const isUploading = state.common.isUploading;

	const industries = [
		{ id: 551, name: '551 Hotellit ja vastaavat majoitusliikkeet' },
		{ id: 552, name: '552 Lomakylät, retkeilymajat yms. majoitus' },
		{
			id: 553,
			name: '553 Leirintäalueet, asuntovaunu- ja matkailuvaunualueet',
		},
		{ id: 681, name: '681 Omien kiinteistöjen kauppa, osa *' },
		{
			id: 682,
			name: '682 Omien tai leasing-kiinteistöjen vuokraus ja hallinta, osa *',
		},
		{
			id: 683,
			name: '683 Kiinteistöalan toiminta palkkio- tai sopimusperusteisesti, osa *',
		},
		{ id: 5610, name: '5610 Ravintolat ja vastaava ravitsemistoiminta' },
		{ id: 5629, name: '5629 Henkilöstö- ja laitosruokailu' },
		{ id: 5630, name: '5630 Baarit ja kahvilat' },
		{ id: 4910, name: '4910 Rautateiden henkilöliikenne, kaukoliikenne' },
		{ id: 4932, name: '4932 Taksiliikenne' },
		{
			id: 4939,
			name: '4939 Muualla luokittelematon maaliikenteen henkilöliikenne',
		},
		{ id: 5010, name: '5010 Meri- ja rannikkovesiliikenteen henkilökuljetus' },
		{ id: 5030, name: '5030 Sisävesiliikenteen henkilökuljetus' },
		{ id: 5110, name: '5110 Matkustajalentoliikenne' },
		{
			id: 7711,
			name: '7711 Autojen ja kevyiden moottoriajoneuvojen vuokraus ja leasing',
		},
		{ id: 7911, name: '7911 Matkatoimistojen toiminta' },
		{ id: 7912, name: '7912 Matkanjärjestäjien toiminta' },
		{ id: 7990, name: '7990 Varauspalvelut, matkaoppaiden palvelut ym.' },
		{ id: 900, name: '900 Kulttuuri- ja viihdetoiminta' },
		{ id: 9102, name: '9102 Museoiden toiminta' },
		{
			id: 9103,
			name: '9103 Historiallisten nähtävyyksien, rakennusten ja vastaavien kohteiden toiminta',
		},
		{
			id: 9104,
			name: '9104 Kasvitieteellisten puutarhojen, eläintarhojen ja luonnonpuistojen toiminta',
		},
		{
			id: 7721,
			name: '7721 Vapaa-ajan ja urheiluvälineiden vuokraus ja leasing',
		},
		{ id: 92, name: '92 Rahapeli- ja vedonlyöntipalvelut' },
		{ id: 9311, name: '9311 Urheilulaitosten toiminta' },
		{ id: 9319, name: '9319 Muu urheilutoiminta' },
		{ id: 9321, name: '9321 Huvi- ja teemapuistojen toiminta' },
		{ id: 9329, name: '9329 Muu huvi- ja virkistystoiminta' },
	];

	// const companySizes = [
	// 	{
	// 		id: 1,
	// 		name: '1-5 henkilöä',
	// 	},
	// 	{
	// 		id: 2,
	// 		name: 'yli 5 henkilöä',
	// 	},
	// ];

	// const companySizes = packages.map((item) => {
	// 	return { id: item.id, name: item.name, sublabel: item.description };
	// });

	return (
		<div>
			<h2>Yrityksen lisätiedot</h2>
			<div>
				<div className="subsection grid-2">
					{/* <div className="input-wrapper">
						<CustomRadio
							label={'Yrityksen henkilömäärä'}
							field={'company_size'}
							handleOnChange={handleOnChange}
							data={data}
							options={companySizes}
							className="full-wide"
						/>
					</div> */}
					<div
						className={`input-wrapper ${
							customErrors.findIndex((i) => {
								return i.field === 'company_vatnumber';
							}) !== -1
								? 'error'
								: ''
						}`}
					>
						<CustomInput
							label={'Y-tunnus'}
							field={'company_vatnumber'}
							handleOnChange={handleOnChange}
							data={data}
						/>
					</div>
					<div className="input-wrapper">
						<CustomSelect
							label={'Yrityksen toimiala'}
							field={'company_industry'}
							handleOnChange={handleOnChange}
							data={data}
							options={industries}
						/>
					</div>
				</div>
				<div className="subsection">
					<h3>Yrityksen sijainti</h3>
					<div className="input-wrapper">
						{/* <CustomInput
							label={"Yrityksen sijainti (radio)"}
							field={"region_id"}
							handleOnChange={handleOnChange}
							data={data}
						/> */}
						{/* <CustomRadio
							label={'Alue'}
							field={'region_id'}
							handleOnChange={handleOnChange}
							data={data}
							options={companyregions}
						/> */}
						<div
							className={`${
								customErrors.findIndex((i) => {
									return i.field === 'companyregion';
								}) !== -1
									? 'error'
									: ''
							}`}
						>
							<CustomCheckboxes
								label={'Alue (valitse vähintään yksi)'}
								field={'companyregion'}
								handleOnChange={handleOnChangeForCheckBoxes}
								data={data}
								options={companyregions}
								className={'sparse'}
							/>
						</div>
					</div>
				</div>
				<div className="subsection grid-2">
					<div>
						<h3>Kuvausteksti</h3>
						<p>
							Kirjoita lyhyt kuvaus (max. 450 merkkiä) yrityksestäsi
							perusmuodossa. Vältä nopeasti vanhenevia tietoja, kuten
							päivämäärään sidottuja tarjouksia tai tapahtumia.
						</p>
					</div>
					<div className="input-wrapper">
						<CustomInput
							label={'Yleinen kuvausteksti'}
							field={'description'}
							placeholder={
								'Esimerkkiteksti: Maauimalamme on virkistävä keidas keskustassa. Sen isossa 50 metrin altaassa on tilaa niin uimareille, vesijuoksijoille, uimahyppääjille kuin muuten vaan pulikoijillekin. Pikkuväelle on tarjolla oma lasten allas. Aurinkoa mahtuu ottamaan laajoilla auringonottotasanteilla ja nurmialueilla.'
							}
							handleOnChange={handleOnChange}
							data={data}
							useTextarea={true}
						/>
					</div>
				</div>
				<div className={`subsection grid-2 `}>
					<div>
						<h3>Kuva</h3>
						<p>
							Lataa kuva julkaistavaksi 100 syytä-sivustolla, Vinkkipankki 100+
							-osiossa. Valitse kuvaksi inspiraatiokuva yrityksestäsi. Emme
							julkaise pelkkiä logoja tai kuvia joihin on sisällytetty tekstiä.{' '}
						</p>
						<p>
							Vähimmäiskoko 800 x 800 px, tiedoston maksimikoko 10MB. Kuva
							rajataan aina neliöksi.
						</p>
						<p>Lisätietoja kuvien käytöstä käyttöehdoista.</p>
					</div>
					<div
						className={`input-wrapper ${
							customErrors.findIndex((i) => {
								return i.field === 'thumb_id';
							}) !== -1
								? 'error'
								: ''
						}`}
					>
						<label className="label">
							<CustomPictureSelector
								data={data}
								field={'thumb_id'}
								handleOnChange={sendFile}
								fileSelectorRef={fileSelectorRef}
								autocomplete={'photo'}
								isRequired={false}
								isUploading={isUploading}
							/>
						</label>
					</div>
				</div>
				<div className="subsection ">
					<div className={`input-wrapper `}>
						<h3>Kategoriat</h3>
						<p style={{ marginLeft: 0 }}>
							Kategoriat toimivat Vinkkipankin suodattimina. Valitse parhaiten
							yritystäsi kuvaavat kategoriat, jotta kuluttajat löytävät
							yrityksesi oikeasta paikasta.
						</p>
						<div
							className={`${
								customErrors.findIndex((i) => {
									return i.field === 'companycategory';
								}) !== -1
									? 'error'
									: ''
							}`}
						>
							<CustomCheckboxes
								label={'Kategoria (valitse vähintään yksi)'}
								field={'companycategory'}
								handleOnChange={handleOnChangeForCheckBoxes}
								data={data}
								options={companycategories}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
