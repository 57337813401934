export const destinationsInitialState = {
	loading: false,
	items: [],
};

export const destinationsReducer = (
	state = destinationsInitialState,
	action
) => {
	switch (action.type) {
		case 'DESTINATIONS_ADD':
		case 'DESTINATIONS_DELETE':
			return state;

		case 'DESTINATIONS_EDITING':
		case 'DESTINATIONS_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'DESTINATIONS_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};

		case 'DESTINATIONS_EDITED':
		case 'DESTINATIONS_ERROR':
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
};
