export const categoriesInitialState = {
	loading: false,
	items: [],
};

export const categoriesReducer = (state = categoriesInitialState, action) => {
	switch (action.type) {
		case 'CATEGORIES_ADD':
		case 'CATEGORIES_DELETE':
			return state;

		case 'CATEGORIES_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'CATEGORIES_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};
		default:
			return state;
	}
};
