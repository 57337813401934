/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../../store/useStore';
import LOGO_IMG from '../../../images/logo3.svg';

export default function Header(props) {
	const { state, dispatch } = useStore();
	const logoutAction = () => {
		dispatch({ type: 'LOGOUT' });
	};
	return (
		<header>
			<Link to={'/'}>
				<img src={LOGO_IMG} alt="100 Syytä matkailla Suomessa" />
			</Link>
			{/* <h1>100 Syytä / Yrityksille</h1> */}
			<ul>
				{/* <li>
					<Link to={'/osallistu'}>Osallistu</Link>
				</li> */}
				{/* <li>
					<Link to={'/#ukk'}>UKK</Link>
				</li> */}
				<li>
					<Link to={'/#ota-yhteytta'}>Ota yhteyttä</Link>
				</li>
				<li>
					{/* {!state.user.loggedIn && (
						<Link to={'/admin'} className="button">
							Kirjaudu
						</Link>
					)} */}
					{state.user.loggedIn && (
						<a className="button" onClick={logoutAction}>
							Kirjaudu ulos
						</a>
					)}
				</li>
			</ul>
		</header>
	);
}
