export const companyregionsInitialState = {
	loading: false,
	items: [],
};

export const companyregionsReducer = (state = companyregionsInitialState, action) => {
	switch (action.type) {
		case "COMPANYREGIONS_ADD":
		case "COMPANYREGIONS_DELETE":
			return state;

		case "COMPANYREGIONS_LOADING":
			return {
				...state,
				loading: true,
			};

		case "COMPANYREGIONS_GET":
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};
		default:
			return state;
	}
};
