import React from 'react';
import { useStore } from '../../store/useStore';
import axios from 'axios';
import { config } from '../config';
import Loader from '../Components/common/Loader';
import LOGO_IMG from '../../images/logo2.svg';

export default function LoginForm() {
	const { state, dispatch } = useStore();

	const { user } = state;

	const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		dispatch({ type: 'LOGINFORM_INPUT', key: name, value: value }, [dispatch]);
	};

	const tryLogin = () => {
		let bodyFormData = new FormData();

		bodyFormData.set('email', user.email);
		bodyFormData.set('password', user.password);

		dispatch({ type: 'LOGIN' });

		axios({
			url: config.apiUrl + '/auth/login',
			method: 'post',
			data: bodyFormData,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				if (response.status === 200 && response.headers.authentication) {
					dispatch({ type: 'LOGIN_SUCCESS', payload: response });
				} else {
					dispatch({ type: 'LOGIN_FAIL', payload: response });
				}
			})
			.catch((Error) => {
				dispatch({ type: 'LOGIN_FAIL' });
			});
	};

	const createInput = (label, name) => {
		return (
			<label className="label-input">
				{label}
				<input
					type="text"
					name={name}
					value={user[name]}
					onChange={(e) => {
						changeInputValue(e);
					}}
					autoComplete="username"
				/>
			</label>
		);
	};
	const createPwd = (label, name) => {
		return (
			<label className="label-password">
				{label}
				<input
					type="password"
					name={name}
					value={user[name]}
					onChange={(e) => {
						changeInputValue(e);
					}}
					autoComplete="current-password"
				/>
			</label>
		);
	};

	return (
		<div className="loginContainer">
			<div className="loginForm">
				<form
					onSubmit={(e) => {
						e.preventDefault();
						e.stopPropagation();
						tryLogin();
					}}
				>
					<img src={LOGO_IMG} alt="100 Syytä matkailla Suomessa" />
					{createInput('Käyttäjätunnus', 'email')}
					{createPwd('Salasana', 'password')}
					{!user.loading && (
						<input
							type="submit"
							className="button "
							name="login"
							value="kirjaudu"
						/>
					)}
					{user.loading && <Loader></Loader>}
				</form>
			</div>
		</div>
	);
}
