export const companycategoriesInitialState = {
	loading: false,
	items: [],
};

export const companycategoriesReducer = (state = companycategoriesInitialState, action) => {
	switch (action.type) {
		case 'COMPANYCATEGORIES_ADD':
		case 'COMPANYCATEGORIES_DELETE':
			return state;

		case 'COMPANYCATEGORIES_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'COMPANYCATEGORIES_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};
		default:
			return state;
	}
};
