import React from 'react';
// import { useStore } from '../../store/useStore';

export default function ContentPage(props) {
	// const { state, dispatch } = useStore();
	const { page } = props;

	const TermsOfService = () => (
		<>
			<section id="TermsOfService">
				<h1>Käyttöehdot</h1>
				<p>
					<strong>
						100 syytä matkailla Suomessa Vinkkipankki 100+ -palvelun käyttöehdot
					</strong>
				</p>
				<p>
					100 syytä matkailla Suomessa-sivusto ja sille lisäosana julkaistu
					Vinkkipankki 100+ -palvelu on Suoma ry:n omistama internet-sivusto,
					jonka tarkoitus on kerätä ja jakaa tietoa Suomessa matkailupalveluja
					tuottavista yrityksistä. Suoma ry on luovuttanut sivuston ylläpito- ja
					hallinnointioikeudet sekä niihin liittyvät vastuut Drama Queen
					Communicationsille kampanjan ajaksi.
				</p>
				<p>
					<br />
				</p>
				<p>
					<strong>Kriteerit yrityksille</strong>
				</p>
				<p>
					Yrityksen tulee olla Suomessa toimiva ja Suomessa matkailupalveluja
					tuottava yritys, joka on avoinna asiakkaille. Yrityksen tulee olla
					liiketoiminnastaan alv-velvollinen ja/tai merkitty
					ennakkoperintärekisteriin (yrityksen tiedot löytyvät Yritys- ja
					yhteisötietojärjestelmä YTJ:stä{' '}
					<a href="http://www.ytj.fi">www.ytj.fi</a>).
				</p>
				<p>
					Yrityksestä tulee olla määriteltävissä yhteystiedot, sijainti, kuvaus
					kohteesta, aukioloajat, sekä palvelua/toimintaa kuvaavat kategoriat.
					Yrityksen Instagram-tiliä voidaan hyödyntää dynaamisen sisällön
					tuottamiseen.
				</p>
				<p>
					Suoma ry. pidättää oikeuden käsitellä ja muokata tekstejä ja kuvia
					tarvittaessa. Kuvia (myös Instagramissa jaettuja) voidaan julkaista
					100 syytä matkailla Suomessa -internet-sivustolla joten osallistumalla
					yritys vakuuttaa, että heillä on käytössään täydet oikeudet
					luovuttamiinsa ja jakamiinsa kuviin. Yritys vastaa myös, että kuvissa
					mahdollisesti esiintyviltä henkilöiltä on saatu lupa kuvien
					julkaisemiseen.
				</p>
				<p>
					<strong>Toimitusehdot</strong>
				</p>
				<p>
					Palveluun mukaan tuleva yritys saa näkyvyyden Vinkkipankki 100 +
					-palvelussa kertaluontoista osallistumismaksua vastaan. Yrityksen
					tiedot lisätään Vinkkipankki 100 + -palveluun heti, kun maksu on
					tullut perille ja yrityksen ilmoittautumislomakkeella toimittamat
					tiedot on tarkistettu, tässä kestää yleensä 1–3 arkipäivää.
				</p>
				<p>
					<strong>Palautusehdot ja peruutukset</strong>
				</p>
				<p>
					Kun palvelu on maksettu, ei maksua palauteta. Halutessaan yritys voi
					kuitenkin milloin tahansa pyytää tietonsa poistettavaksi Vinkkipankki
					100 + -palvelusta. Osallistumismaksua ei kuitenkaan palauteta.
					Yrityksen tietojen poistosta voi olla yhteydessä asiakaspalveluumme
					100syyta@dqcomms.com. Viive yrityksen tietojen poistamisessa on noin
					1–3 arkipäivää.
				</p>

				<p>
					<strong>Laatukriteerit kuville</strong>
				</p>
				<p>
					Valitse yrityksesi palvelua, tapahtumaa tai aktiviteettia parhaiten
					kuvaavat houkuttelevat ja korkealaatuiset kuvat.
				</p>
				<p>
					Kuvien päälle ei saa lisätä tekstiä, päivämääriä, vesileimoja tai
					muuta vastaavaa.
				</p>
				<p>
					Varmistathan kuvien käyttöoikeudet, sillä kuvia voidaan jakaa 100
					syytä matkailla Suomessa -sivuston kautta.
				</p>
				<p>
					Lomakkeen kautta jaettavan kuvan koko tulee olla vähintään 800 x 800
					pikseliä, tiedoston JPEG-muodossa eikä sen koko saa ylittää 10 Mb.
					Kuvat rajataan aina sivustolla näkyessään neliökokoon. Myös
					Instagramin kautta jaetut kuvat rajautuvat neliökokoon sivustolla.
				</p>
				<p>
					<br />
				</p>
				<p>
					<strong>K&auml;ytt&ouml;ehdot</strong>
				</p>
				<p>
					Henkilöitä ja yrityksiä, jotka syöttävät tietoja palveluun kutsutaan
					alla Käyttäjäksi.
				</p>
				<p>
					Käyttämällä 100 syytä matkailla Suomessa Vinkkipankki 100+ -palvelua
					(myöhemmin Palvelu) ja syöttämällä sinne tietoja Käyttäjää sitoo ja
					Käyttäjään sovelletaan seuraavia käyttöehtoja:
				</p>
				<p>
					Käyttäjä toimittaa oman yrityksensä omistaman paikan tai kohteen
					tiedot syöttämällä tiedot Palvelussa pyydetyllä tavalla. Toimittamalla
					tiedot Palveluun Käyttäjä myöntää Suoma ry:lle vapaan ja
					rajoittamattoman käyttöoikeuden tietoihin ja oikeuden julkaista
					toimittamiaan tietoja Suoma ry:n omistamalla 100 syytä matkailla
					Suomessa -sivustolla. Kuvien osalta sovelletaan Käyttäjän antamia
					käyttöoikeuksia.
				</p>
				<p>
					Käyttäjä hyväksyy, että Suoma ry:llä on oikeus muokata tietoja joko
					osittain tai kokonaan. Suoma ry. päättää Käyttäjän toimittaman tiedon
					(teksti ja kuvat) tai sen osan julkaisemisesta. Suoma ry:llä ei ole
					velvollisuutta tiedon julkaisemiseen.
				</p>
				<p>
					Kuvien osalta Käyttäjä vastaa siitä, että hänellä on täydet oikeudet
					toimittamaansa materiaaliin ja oikeus luovuttaa se Suoma ry:lle
					julkaistavaksi 100syytä.fi-verkkosivuilla. Mikäli Käyttäjä itse ei ole
					tekijänoikeuden tai muun tarvittavan oikeuden haltija tai omistaja,
					hän vastaa siitä, että hänellä on oikeudenhaltijan lupa materiaaliin
					ja sen toimittamiseen Suoma ry:lle edellä kerrottua tarkoitusta
					varten.
				</p>
				<p>
					Käyttäjä vastaa siitä, ettei hänen lähettämänsä tiedot loukkaa
					kolmannen tekijänoikeutta tai muuta lakiin tai sopimuksiin perustuvaa
					oikeutta. Käyttäjä vastaa siitä, että annetut tiedot eivät ole lain
					tai hyvän tavan vastaista.
				</p>
				<p>
					Käyttäjä sitoutuu olemaan väärinkäyttämättä Palvelua, kuten
					kilpailijansa tai tämän liikkeen tai liiketoiminnan mustamaalaamiseen
					tai muihin kilpailullisesti arveluttaviin tarkoituksiin.
				</p>
				<p>
					Suoma ry:llä on omistus- ja tekijänoikeus sivustoihin ja niiden
					toteutukseen eikä Käyttäjällä ole oikeutta osaksikaan kopioida tai
					käyttää sivustoja tai niiden ideaa omaan tarkoitukseensa tai muihin
					Suoma ry:n oikeuksia loukkaavaan toimintaan. Suoma ry. pidättää kaikki
					oikeudet 100 syytä matkailla Suomessa Vinkkipankki 100+- palveluun ja
					sisältöön.
				</p>
				<p>
					Suoma ry:llä on oikeus poistaa sellaisia tietoja tietokannastaan,
					johon se arvioi sisältyvän em. loukkaavaa, manipuloivaa taikka
					rikollista tekstiä tai elementtejä.
				</p>
				<p>
					Suoma ry tarkistaa ja editoi Palvelun kautta syötetyt tiedot niin
					laadullisesti kuin yllämainittujen negatiivisten elementtien
					poistamiseksi.
				</p>
				<p>
					Suoma ry ei takaa eikä vastaa Palvelun kautta syötetyn sisällön tai
					sen osan taikka avoimen datan kautta saatujen tietojen
					oikeellisuudesta ja paikkansapitävyydestä.
				</p>
				<p>
					Suoma ry ei vastaa Palvelun kautta syötettyjen tietojen tai sisällön
					perusteella tehtävien johtopäätösten tai toimenpiteiden aiheuttamista
					välittömästä tai välillisestä vahingoista tai menetyksestä. Tämä
					käsittää mm. menetetyn liikevoiton, liikearvon tai maineen tai
					tietojen menetyksen.
				</p>
				<p>
					Käyttäjän ja Suoma ry:n välisiin riitaisuuksiin tai näistä
					Käyttöehdoista johtuviin erimielisyyksiin sovelletaan Suomen lakia.
					Jos erimielisyydet ei neuvotteluteitse saada ratkaistua, riidat
					ratkaistaan Helsingin käräjäoikeudessa.
				</p>
				<p>
					<br />
				</p>
				<p>
					Katso my&ouml;s <a href="/rekisteriseloste">rekisteriseloste</a>.
				</p>
				<p>
					Ep&auml;selviss&auml; tapauksissa ottakaa yhteytt&auml;:
					100syyta@dqcomms.com
				</p>
				<p>
					<br />
					<br />
				</p>
				<p>
					<strong>Verkkokaupan yhteystiedot</strong>
				</p>
				<p>
					• Y-tunnus: 2420099-8<br/>
					• Yrityksen nimi: Drama Queen Communications Oy<br/>
					• Postiosoite: Itäinen pitkäkatu 21 A, Turku<br/>
					• Puhelinnumero: +358 400 295850<br/>
					• Sähköpostiosoite: 100syyta@dqcomms.com<br/>	
				</p>
				<p>
					<strong>Maksupalvelutarjoaja</strong>
				</p>
				<p>
					Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii
					Paytrail Oyj (2122839-7) yhteistyössä suomalaisten pankkien ja
					luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana tiliotteella
					tai korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on
					maksulaitoksen toimilupa. Reklamaatiotapauksissa pyydämme ottamaan
					ensisijaisesti yhteyttä tuotteen toimittajaan.
				</p>
				<p>
					Paytrail Oyj, y-tunnus: 2122839-7<br/>
					Innova 2<br/>
					Lutakonaukio 7<br/>
					40100 Jyväskylä
				</p>
			</section>
			{/* <section id="TermsOfService-SE">
				<h1>Användningsvillkor</h1>
				<p>
					<strong>
						Användningsvillkor för tjänsten 100 syytä matkailla Suomessa
						Tipsbank 100+
					</strong>
				</p>
				<p>
					Webbplatsen 100 syytä matkailla Suomessa och tjänsten Tipsbank 100+,
					som har publicerats som tillägg till den, är en webbplats som ägs av
					Suoma ry, vars avsikt är att samla in och dela information om företag
					som producerar turisttjänster i Finland. Suoma ry har överfört
					underhålls- och administrationsrättigheterna för webbplatsen och det
					därmed sammanhängande ansvaret till Drama Queen Communications för den
					tid kampanjen pågår.
				</p>
				<p>
					<strong>Kriterierna för företag</strong>
				</p>
				<p>
					Företaget måste vara ett företag som är verksamt i Finland och
					tillhandahåller turisttjänster i Finland, tjänster som är öppna för
					kunder. Företaget måste vara momspliktigt för sin verksamhet och/eller
					registrerat i förskottsuppbördsregistret (företagets uppgifter finns i
					Företags- och organisationsdatasystemet{' '}
					<a href="http://www.ytj.fi">www.ytj.fi</a>).
				</p>
				<p>
					Följande uppgifter om företaget måste fastställas, kontaktuppgifter,
					plats, beskrivning av destinationen, öppettider och kategorier som
					beskriver tjänsten/aktiviteten. Företagets Instagram-konto kan
					användas för att generera dynamiskt innehåll.
				</p>
				<p>
					Suoma ry. förbehåller sig rätten att vid behov behandla och redigera
					texter och bilder. Bilder (även på Instagram delade) kan publiceras på
					webbplatsen <strong>100 syytä matkailla Suomessa</strong>, dvs. genom
					att delta försäkrar företaget att det besitter fulla rättigheter till
					de bilder som det överlåter och delar. Företaget ansvarar även för att
					eventuella personer som visas på bilderna har gett sitt tillstånd till
					att bilderna publiceras.
				</p>
				<p>
					<br />
					<br />
				</p>
				<p>
					<strong>Kvalitetskriterier för bilder</strong>
				</p>
				<p>
					Välj attraktiva bilder av hög kvalitet som bäst beskriver ditt
					företags tjänst, ett evenemang eller en aktivitet.
				</p>
				<p>
					Datum, vattenstämplar eller annat motsvarande får inte läggas till på
					bilderna.
				</p>
				<p>
					Säkerställ bildernas behörighet, eftersom bilder kan delas genom
					webbplatsen 100 syytä matkailla Suomessa.
				</p>
				<p>
					Storleken på bilden som delas via formuläret måste vara minst 800 x
					800 pixlar, filen i JPEG-format och får inte överstiga 10 Mb. Bilderna
					beskärs varje gång de visas i kvadratisk storlek på webbplatsen.
					Bilder som delas via Instagram beskärs också till kvadratisk storlek
					på webbplatsen.
				</p>

				<p>
					<strong>Användningsvillkor</strong>
				</p>
				<p>
					Individer och företag som matar in information i tjänsten kallas nedan
					Användaren.
				</p>
				<p>
					Genom att använda tjänsten 100 syytä matkailla Suomessa Tipsbank 100+
					(senare Tjänsten) och genom att mata in information till tjänsten är
					Användaren förbunden till och på Användaren tillämpas följande
					användningsvillkor:
				</p>
				<p>
					Användaren tillhandahåller information om platsen som företaget äger
					eller information om destinationen genom att mata in informationen
					såsom ombeds i Tjänsten. Genom att tillhandahålla information i
					Tjänsten beviljar Användaren Suoma ry fri och obegränsad nyttjanderätt
					till informationen och rätten att publicera den tillhandahållna
					informationen på webbplatsen 100 syytä matkailla Suomessa, vilken ägs
					av Suoma ry. För bildernas del tillämpas de av Användaren beviljade
					nyttjanderätterna.
				</p>
				<p>
					Användaren accepterar att Suoma ry har rätt att redigera uppgifterna
					antingen helt eller delvis. Suoma ry. beslutar om publicering av den
					information (text och bilder) som tillhandahålls av Användaren, eller
					en del därav. Suoma ry har ingen skyldighet att publicera
					informationen.
				</p>
				<p>
					För bildernas del är Användaren ansvarig för att denne har fulla
					rätter till det tillhandahållna materialet och har rätt att överlämna
					det till Suoma ry för publicering på webbplatsen 100syytä.fi. Om
					Användaren inte är innehavare eller ägare till upphovsrätten eller
					annan nödvändig rättighet, är denne ansvarig för att denne har
					rättsinnehavarens tillstånd till materialet och tillhandahållandet av
					det till Suoma ry för det ovan beskrivna ändamålet.
				</p>
				<p>
					Användaren ansvarar för att dennes inlämnade information inte bryter
					mot tredje parts upphovsrätt eller någon annan rätt som baserar sig på
					lag eller avtal. Användaren ansvarar för att informationen inte
					strider mot lagen eller god praxis.
				</p>
				<p>
					Använder förbinder sig till att inte missbruka Tjänsten, såsom till
					att förtala sin konkurrent eller dennes företag eller affärsverksamhet
					eller till andra konkurrensmässigt tvivelaktiga ändamål.
				</p>
				<p>
					Suoma ry har ägande- eller upphovsrätt till webbplatsen och till
					genomförande av den och Användaren har inte rätt att till någon del
					kopiera eller använda webbplatsen eller idén med den för eget syfte
					eller till annan verksamhet som kränker Suoma ry:s rättigheter. Suoma
					ry. förbehåller sig alla rättigheter till tjänsten och innehållet i
					100 syytä matkailla Suomessa Tipsbanken 100+.
				</p>
				<p>
					Suoma ry har rätt att radera sådan information från sin databas, om
					Suoma ry bedömer att den innehåller t.ex. kränkande, manipulerande
					eller kriminell text eller element.
				</p>
				<p>
					Suoma ry granskar och redigerar information som har matats in i
					Tjänsten för att radera såväl kvalitativ och såsom ovan angivna
					negativa element.
				</p>
				<p>
					Suoma ry garanterar inte och ansvarar inte för autenticiteten och
					riktigheten i informationen eller någon del av den som har matats in i
					Tjänsten eller för information som har erhållits via öppna data.
				</p>
				<p>
					Suoma ry ansvarar inte för direkta eller indirekta skador eller för
					förluster som har orsakats av slutsatser eller åtgärder som har
					vidtagits på basen av informationen eller innehållet i Tjänsten. Detta
					inkluderar bl.a. förlorad rörelsevinst, goodwill eller rykte eller
					förlust av data.
				</p>
				<p>
					Finlands lag tillämpas på tvister mellan Användaren och Suoma ry eller
					tvister som uppstår till följd av dessa Användningsvillkor. Om
					tvisterna inte kan lösas genom förhandling, avgörs tvisterna i
					Helsingfors tingsrätt.
				</p>
				<p>
					<br />
					<br />
					<br />
				</p>
				<p>
					Se även <a href="/rekisteriseloste">sekretesspolicyn</a>.
				</p>
				<p>Vid oklara fall, vänligen kontakta: 100syyta@dqcomms.com</p>
			</section> */}
		</>
	);

	const PrivacyPolicy = () => (
		<>
			<section id="PrivacyPolicy">
				<h1>Rekisteriseloste</h1>
				<p>
					<strong>100 syyt&auml; matkailla Suomessa / Rekisteriseloste</strong>
				</p>
				<ol>
					<li>
						<strong> Rekisterinpit&auml;j&auml;</strong>
					</li>
				</ol>
				<p>Drama Queen Communications Oy, Y-tunnus 2420099-8.</p>
				<ol start="2">
					<li>
						<strong> Rekisteriasioita hoitaa</strong>
					</li>
				</ol>
				<p>Drama Queen Communications Oy</p>
				<p>Lauri Suoper&auml;</p>
				<p>
					<a href="mailto:100syyta@dqcomms.com">100syyta@dqcomms.com</a>
				</p>
				<ol start="3">
					<li>
						<strong> Rekisterin nimi</strong>
					</li>
				</ol>
				<p>
					100 syyt&auml; matkailla Suomessa -sivuston Vinkkipankki 100+ yritys-
					ja yhteyshenkil&ouml;rekisteri.
				</p>
				<ol start="4">
					<li>
						<strong>
							{' '}
							Henkil&ouml;tietojen k&auml;ytt&ouml;tarkoitus&nbsp;
						</strong>
					</li>
				</ol>
				<p>
					Henkilötietoja käsitellään yritysten yhteyshenkilöiden tietoina.
					Henkilötietoja ei käytetä markkinointitarkoituksiin eikä luovuteta
					kolmansille osapuolille ilman asiakkaan suostumusta. Yrityksestä
					kerättävät ja julkisesti jaettavat tiedot perustuvat yrityksen
					yhteyshenkilön antamaan suostumukseen.
				</p>
				<p>
					Tietoja säilytetään rekisterissä vain 100 syytä matkailla Suomessa
					Vinkkipankki 100+ -kampanja-ajan. Kun kampanja on päättynyt
					(viimeistään 31.5.2021 asti), Drama Queen Communications Oy poistaa
					henkilöstä ja yrityksistä kerätyt tiedot rekisteristään.
				</p>
				<ol start="5">
					<li>
						<strong> Rekisterin tietosis&auml;lt&ouml;</strong>
					</li>
				</ol>
				<p>
					Rekisterissä voidaan käsitellä kaikista rekisteröidyistä henkilöistä
					seuraavia tietoja: henkilön, nimi, osoite, sähköpostiosoite,
					puhelinnumero ja mahdollisesti henkilön lähettämät muut materiaalit,
					jotka liittyvät kampanjaan osallistumiseen (kuten palveluun lähetetyt
					kuvat).
				</p>
				<ol start="6">
					<li>
						<strong> S&auml;&auml;nn&ouml;nmukaiset tietol&auml;hteet</strong>
					</li>
				</ol>
				<p>
					Tietoja kerätään henkilöstä tai yrityksestä vain silloin, kun hän
					oma-aloitteisesti jakaa ne palveluntarjoajalle osallistumalla 100
					syytä matkailla Suomessa Vinkkipankki 100+ -palveluun.
				</p>
				<ol start="7">
					<li>
						<strong> Tietojen luovuttaminen ja siirt&auml;minen</strong>
					</li>
				</ol>
				<p>
					Drama Queen Communications Oy:llä ei ole oikeutta luovuttaa
					rekisteriinsä kerättyjä tietoja eteenpäin.
				</p>
				<ol start="8">
					<li>
						<strong> Rekisterin suojaus</strong>
					</li>
				</ol>
				<p>
					Rekisteriä ylläpidetään teknisenä tallenteena. Tietojärjestelmä on
					suojattu ja rekisteriä käyttävät ainoastaan henkilöt, joiden
					toimenkuvaan sen käyttö kuuluu.
				</p>
				<ol start="9">
					<li>
						<strong> Tarkastus-, kielto- ja korjausoikeus</strong>
					</li>
				</ol>
				<p>
					Rekisterissä olevalla henkilöllä on henkilötietolain mukainen oikeus
					tarkastaa, mitä häntä tai hänen yritystään koskevia tietoja on
					rekisteriin tallennettu.
				</p>
				<p>Drama Queen Communications Oy</p>
				<p>www.dqcomms.com</p>
				<p>
					<br />
					<br />
				</p>
			</section>
			{/* <section id="PrivacyPolicy-SE">
				<h1>Sekretesspolicy</h1>
				<p>
					<strong>100 syytä matkailla Suomessa / Sekretesspolicy</strong>
				</p>
				<ol>
					<li>
						<strong>Personuppgiftsansvarig</strong>
					</li>
				</ol>
				<p>Drama Queen Communications Oy, Y-tunnus 2420099-8.</p>
				<ol start="2">
					<li>
						<strong>Registerärenden hanteras av</strong>
					</li>
				</ol>
				<p>Drama Queen Communications Oy</p>
				<p>Lauri Suoper&auml;</p>
				<p>
					<a href="mailto:100syyta@dqcomms.com">100syyta@dqcomms.com</a>
				</p>
				<ol start="3">
					<li>
						<strong>Registrets namn</strong>
					</li>
				</ol>
				<p>
					100 syytä matkailla Suomessa, Tipsbanken 100+ register över företag
					och kontaktpersoner.
				</p>
				<ol start="4">
					<li>
						<strong>Syftet med behandlingen av personuppgifter </strong>
					</li>
				</ol>
				<p>
					Personuppgifter behandlas som uppgifter från företagens
					kontaktpersoner. Personuppgifter används inte i marknadsföringssyfte
					och lämnas inte ut till tredje part utan kundens samtycke. Information
					som samlas in och delas offentligt baserar sig på samtycke från
					företagets kontaktperson.
				</p>
				<p>
					Uppgifterna lagras i registret av endast 100 syytä matkailla Suomessa,
					under tiden som kampanjen Tipsbank 100+ pågår. När kampanjen har
					avslutats (senast 30.4.2024) kommer Drama Queen Communications Oy att
					radera de uppgifter som samlats in från personen och företagen från
					registret.
				</p>
				<ol start="5">
					<li>
						<strong>Registrets datainnehåll</strong>
					</li>
				</ol>
				<p>
					Följande uppgifter kan behandlas i registret för alla registrerade
					personer: person, namn, adress, e-postadress, telefonnummer och
					eventuellt annat material som skickats av personen i samband med
					deltagandet i kampanjen (t.ex. bilder som skickats till tjänsten).
				</p>
				<ol start="6">
					<li>
						<strong>Regelmässiga informationskällor</strong>
					</li>
				</ol>
				<p>
					Information samlas in om en person eller ett företag endast när de på
					eget initiativ delar den till tjänsteleverantören genom att delta i
					100 syytä matkailla Suomessa Tipsbank 100+-tjänsten.
				</p>
				<ol start="7">
					<li>
						<strong>Utlämnande och överföring av uppgifter</strong>
					</li>
				</ol>
				<p>
					Drama Queen Communications Oy har inte rätt att vidarebefordra den
					information som har samlats in i registret.
				</p>
				<ol start="8">
					<li>
						<strong>Skydd av registret</strong>
					</li>
				</ol>
				<p>
					Registret upprätthålls som en teknisk inspelning. Informationssystemet
					är skyddat och registret används endast av personer, vilkas
					befattningsbeskrivning omfattar användning av det.
				</p>
				<ol start="9">
					<li>
						<strong>Rätt till inspektion, förbud och korrigering</strong>
					</li>
				</ol>
				<p>
					En person som är införd i registret har enligt personuppgiftslagen
					rätt att granska vilka uppgifter som har sparats om honom eller henne
					eller dennes företag i registret.
				</p>
				<p>Drama Queen Communications Oy</p>
				<p>www.dqcomms.com</p>
				<p>
					<br />
					<br />
				</p>
			</section> */}
		</>
	);

	return (
		<div className="contentpage">
			{page === 'TermsOfService' ? <TermsOfService /> : <PrivacyPolicy />}
		</div>
	);
}
