import React from 'react';
import { HeaderAmount } from './HeaderAmount';

export default function CompaniesHeader(props) {
	// const { state, dispatch } = useStore();
	const rawdata = props.data ? props.data : [];
	const dataInView = props.dataInView ? props.dataInView : [];

	// eslint-disable-next-line eqeqeq
	const data = dataInView.length != 0 ? dataInView : rawdata;

	// const isPlaces = props.events ? false : true;

	const countStarted = data.filter((i) => i.status === 0).length;
	const countForm = data.filter(
		(i) => i.is_published !== 1 && i.status === 1 && i.is_paid === 0
	).length;
	const countPaid = data.filter(
		(i) => i.is_published !== 1 && i.status === 1 && i.is_paid === 1
	).length;
	const countApproved = data.filter((i) => i.is_published === 1).length;
	const countPublished = dataInView.filter((i) => i.is_published === 1).length;
	// const countInView = dataInView.length;

	return (
		<>
			<div className="common-list-data">
				<HeaderAmount label="Yhteensä" value={data.length} />
				<HeaderAmount label="Aloitettuja" value={countStarted} />
				<HeaderAmount label="Lähetettyjä" value={countForm} />
				<HeaderAmount label="Maksettuja" value={countPaid} />
				<HeaderAmount label="Hyväksyttyjä" value={countApproved} />
				<HeaderAmount label="Julkaistuja" value={countPublished} />
			</div>
			{/* <HeaderAdd add={props.add} /> */}
		</>
	);
}
