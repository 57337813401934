import React, { useEffect, useCallback } from 'react';
import { useStore } from '../../../store/useStore';
import { apiClient } from '../../apiCall';
import Loader from './Loader';
import Timestamp from './Timestamp';

const fetchInstagram = (apiCall, account) => {
	apiCall.INSTAGRAM({ username: account });
};

export const useDebouncedEffect = (effect, delay, deps) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const callback = useCallback(effect, deps);

	useEffect(() => {
		const handler = setTimeout(() => {
			callback();
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [callback, delay]);
};

const InstagramImg = React.memo((props) => {
	const { item } = props;

	return (
		<div className="ig-img">
			<p>
				<Timestamp timestamp={item.published_at} /> - @
				{item.attachments.instagram_item.username}
			</p>

			<a href={item.url} target="_blank" rel="noopener noreferrer">
				<img
					src={item.cover_url}
					title={item.attachments.instagram_item.caption}
					alt=" "
				/>
			</a>
			{/* <div><p>{item.text}</p></div> */}
			{/* <div className="actions">
				<button
					type="button"
					onClick={() => {
						props.setAsMainImage(item.image_original.url);
					}}
					className="secondary"
				>
					Kopioi pääkuvaksi
				</button>
			</div> */}
		</div>
	);
});

const InstagramFeed = (props) => {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const { instagram } = state;
	const { account, setAsMainImageCallback } = props;

	// eslint-disable-next-line no-unused-vars
	var timeout_id = null;

	const setAsMainImage = (url) => {
		var data = [{ url: url }];
		if (typeof setAsMainImageCallback == 'function') {
			apiCall.ADD('files', data).then((response) => {
				setAsMainImageCallback(response);
			});
		}
	};

	useEffect(() => {
		dispatch({ type: 'INSTAGRAM_LOADING' });		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	useDebouncedEffect(
		() => {
			console.log(account && !instagram.accounts[account], 'accounts', account, instagram)
			if (account && !instagram.accounts[account]) {
				timeout_id = setTimeout(() => {
					fetchInstagram(apiCall, account);
				}, 1000);
			} else {
				timeout_id = setTimeout(() => {
					dispatch({ type: 'INSTAGRAM_CACHE' });
				}, 150);
			} 
				
		},
		1000,
		[account]
	);

	// useEffect(() => {
	// 	console.log('useEffect', account);
	// 	if (account && !instagram.accounts[account]) {
	// 		dispatch({ type: 'INSTAGRAM_LOADING' });
	// 		timeout_id = setTimeout(() => {
	// 			fetchInstagram(apiCall, account);
	// 		}, 1000);
	// 	}
	// }, [account]);

	if (!account) {
		return <></>;
	}

	if (instagram.isLoading) {
		return <Loader className="dark"></Loader>;
	}

	const feedItems = instagram?.accounts?.[account]?.articles ?? [];
	if (feedItems) {
		const items = feedItems.map((item) => {
			return (
				<InstagramImg
					item={item}
					key={item.id}
					setAsMainImage={setAsMainImage}
				></InstagramImg>
			);
		});
		return (
			<div className="ig-feed">
				<div className="ig-wrap">{items}</div>
			</div>
		);
	} else {
		return <></>;
	}
};

export default InstagramFeed;
