export const regionsInitialState = {
	loading: false,
	items: [],
};

export const regionsReducer = (state = regionsInitialState, action) => {
	switch (action.type) {
		case "REGIONS_ADD":
		case "REGIONS_DELETE":
			return state;

		case "REGIONS_LOADING":
			return {
				...state,
				loading: true,
			};

		case "REGIONS_GET":
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};
		default:
			return state;
	}
};
