import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useTracking = (trackingId) => {
	const { listen } = useHistory();
	//   const trackingId = process.env.GA_MEASUREMENT_ID;

	useEffect(() => {
		const unlisten = listen((location) => {
			const { hash } = location;
			// const isAdmin = pathname.includes('/admin');

			if (hash !== '') {
				// Push onto callback queue so it runs after the DOM is updated,
				// this is required when navigating from a different page so that
				// the element is rendered on the page before trying to getElementById.
				setTimeout(() => {
					const id = hash.replace('#', '');
					const element = document.getElementById(id);
					if (element) {
						element.scrollIntoView();
					}
				}, 0);
			}

			// if (isAdmin) {
			// 	window.Intercom('update');
			// 	return;
			// }
			if (!window.gtag) return;
			if (!trackingId) {
				console.log(
					'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
				);
				return;
			}

			// Call GA
			window.gtag('config', trackingId, { page_path: location.pathname });
			// Update Intercom url
			// window.Intercom('update');
		});

		return unlisten;
	}, [trackingId, listen]);
};
