/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useStore } from '../../../store/useStore';
import { apiClient } from '../../apiCall';
import { Redirect, NavLink } from 'react-router-dom';

import Loader from '../../Components/common/Loader';

import { createHandlers } from '../../Components/common/CustomInput';
import { config } from '../../config';
import PlaceNotesAndHistory from '../../Components/admin/PlaceNotesAndHistory';
import { toast } from 'react-toastify';
import {
	ViewStatus,
	ViewBasicInfo,
	ViewLocation,
	ViewInstagram,
	ViewLinks,
	ViewCategories,
	// ViewSummary,
	// PaymentButton,
	// ReceiptButton,
	ViewAdditional,
	ViewImages,
} from '../../Components/flow/views';

export default function Destination(props) {
	const { state, dispatch } = useStore();
	const [redirect, setRedirect] = useState(false);
	const apiCall = apiClient(state, dispatch);
	const [data, setData] = useState({});
	const formRef = useRef();
	const { id } = props;

	useEffect(() => {
		let isMounted = true;
		if (id) {
			apiCall.GET('destinations/' + id).then((response) => {
				if (response.status === 200) {
					if (isMounted) {
						setData({
							...response.data.data,
						});
					}
				}
			});
		}
		return () => {
			isMounted = false;
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const {
		handleOnChangeForCheckBoxes,
		handleOnChange,
		handleOnChangeArray,
		handleOnCustomChange,
		handleSwitch,
		handleOnCKEditorChange,
		sendFile,
	} = createHandlers(data, setData, apiCall, state);

	const customErrors = [];

	const completeStep = (e) => {
		const form = formRef.current;
		const isValid = form && form.checkValidity();
		const isCustomValid = customErrors.length === 0;

		// console.log(id, isValid, isCustomValid);
		if (id) {
			if (isValid) {
				e.preventDefault();
				if (isCustomValid) {
					var saveData = { ...data };

					apiCall.EDIT('destinations', id, saveData).then((response) => {
						if (response.status === 200) {
							setData({
								...response.data.data,
							});
						}
					});
				} else {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			} else {
				toast.warning('Tarkista kentät');
			}
		}
	};

	const removePlace = () => {
		if (window.confirm('Haluatko varmasti poistaa tämän yrityksen?')) {
			apiCall.DELETE('destinations', data.id).then(() => {
				apiCall.GET('destinations');
				setRedirect(true);
			});
		}
	};

	if (redirect === true) {
		return <Redirect to="/admin/destinations" />;
	}

	if (id && !data.id) {
		return <Loader className="dark"></Loader>;
	}

	if (state.destinations.loading) {
		return <Loader className="dark"></Loader>;
	}

	const formviews = [
		ViewStatus,
		ViewBasicInfo,
		ViewImages,
		ViewAdditional,
		ViewLocation,
		ViewCategories,
		ViewInstagram,
		ViewLinks,
	];

	return (
		<div className="company-form">
			<header>
				<div className="inner">
					<div className="top">
						<h1>
							<span className="category">Kohde #{data?.numero ?? ''}</span>
							{data.name === '' || data.name === null
								? 'Nimeämätön kohde '
								: data.name}
							<a
								className="extlink"
								// href={`${config.siteUrl}/goto.php?id=${data.id}`}
								href={`${config.siteUrl}/${data.previewurl}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								Näytä sivustolla »
							</a>
						</h1>
					</div>
				</div>
			</header>
			<div className="header-bottom">
				<nav>
					<ul className="">
						<li>
							<NavLink activeClassName="active" to={`#tiedot`}>
								Tiedot
							</NavLink>
						</li>
						<li>
							<NavLink activeClassName="active" to={`#media`}>
								Media
							</NavLink>
						</li>
						<li>
							<NavLink activeClassName="active" to={`#lisa`}>
								Lisäsisältö
							</NavLink>
						</li>
						<li>
							<NavLink activeClassName="active" to={`#lokaatio`}>
								Lokaatio
							</NavLink>
						</li>
						<li>
							<NavLink activeClassName="active" to={`#kategoriat`}>
								Kategoria
							</NavLink>
						</li>
						<li>
							<NavLink activeClassName="active" to={`#insta`}>
								Instagram
							</NavLink>
						</li>
						<li>
							<NavLink activeClassName="active" to={`#linkit`}>
								Ohjauslinkitykset
							</NavLink>
						</li>

						{data.id && (
							<li>
								<a
									// href={`${config.siteUrl}/goto.php?id=${data.id}`}
									href={`${config.siteUrl}${data.previewurl}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									Näytä sivustolla »
								</a>
							</li>
						)}
					</ul>
				</nav>
			</div>
			<PlaceNotesAndHistory data={data} mode={'destinations'} id={data.id} />
			{formviews.map((View, index) => {
				return (
					<React.Fragment key={index}>
						<a name={`t-${index}`} />
						<section>
							<form ref={formRef}>
								<View
									data={data}
									handleOnChange={handleOnChange}
									handleSwitch={handleSwitch}
									handleOnChangeArray={handleOnChangeArray}
									handleOnChangeForCheckBoxes={handleOnChangeForCheckBoxes}
									handleOnCKEditorChange={handleOnCKEditorChange}
									handleOnCustomChange={handleOnCustomChange}
									apiCall={apiCall}
									sendFile={sendFile}
									customErrors={customErrors}
								/>
							</form>
						</section>
					</React.Fragment>
				);
			})}

			<section>
				{data.id && (
					<button type="button" className="delete" onClick={removePlace}>
						Poista kohde?
					</button>
				)}
			</section>
			<div className="submit-holder">
				{/* <button onClick={() => console.log(data)}>test</button> */}
				<button type="submit" form="place-form" onClick={completeStep}>
					{data.id ? 'Tallenna muutokset' : 'Lisää kohde'}
				</button>
			</div>
		</div>
	);
}
