export const kohdekortitInitialState = {
	loading: false,
	items: [],
};

export const kohdekortitReducer = (state = kohdekortitInitialState, action) => {
	switch (action.type) {
		case 'KOHDEKORTIT_ADD':
		case 'KOHDEKORTIT_DELETE':
			return state;

		case 'KOHDEKORTIT_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'KOHDEKORTIT_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};
		default:
			return state;
	}
};
