import React from 'react';
import ICON_ADD from '../../images/add.svg';
import ICON_PEN from '../../images/pen.svg';
import ICON_CHECK from '../../images/checkmark.svg';
import ICON_STATUS_ARCHIVED from '../../images/status_archived.svg';
import ICON_STATUS_HIDDEN from '../../images/status_hidden.svg';
import ICON_STATUS_LIVE from '../../images/status_live.svg';
import ICON_STATUS_OLD from '../../images/status_old.svg';
import ICON_VISIBILITY_OK from '../../images/visibility_ok.svg';
import ICON_VISIBILITY_OVER from '../../images/visibility_over.svg';
import ICON_VISIBILITY_UNDER from '../../images/visibility_under.svg';
import ICON_FILTER_ARROW from '../../images/filter_arrow.svg';
import ICON_DROPDOWN_ARROW from '../../images/dropdown_arrow.svg';
import ICON_CLOSE from '../../images/close2.svg';
import ICON_SWITCH_OFF from '../../images/toggle_off.svg';
import ICON_SWITCH_ON from '../../images/toggle_on.svg';
import { ReactComponent as ICON_DUPLICATE } from '../../images/duplicate.svg';
import ICON_TRASH from '../../images/trash.svg';

export const ICON_ADD_IMG = () => {
	return <img src={ICON_ADD} alt="Lisää uusi paikka" className="icon" />;
};

export const ICON_PEN_IMG = () => {
	return <img src={ICON_PEN} alt="Muokkaa" className="icon" />;
};

export const ICON_CHECK_IMG = () => {
	return <img src={ICON_CHECK} alt="ok" className="icon" />;
};

export const ICON_VISIBILITY_OK_IMG = () => {
	return <img src={ICON_VISIBILITY_OK} alt="OK" className="icon" />;
};
export const ICON_VISIBILITY_OVER_IMG = () => {
	return <img src={ICON_VISIBILITY_OVER} alt="+" className="icon" />;
};
export const ICON_VISIBILITY_UNDER_IMG = () => {
	return <img src={ICON_VISIBILITY_UNDER} alt="-" className="icon" />;
};

export const ICON_STATUS_ARCHIVED_IMG = () => {
	return <img src={ICON_STATUS_ARCHIVED} alt="Arkistoitu" className="icon" />;
};

export const ICON_STATUS_HIDDEN_IMG = () => {
	return <img src={ICON_STATUS_HIDDEN} alt="Piilotettu" className="icon" />;
};

export const ICON_STATUS_LIVE_IMG = () => {
	return <img src={ICON_STATUS_LIVE} alt="Julkaistu" className="icon" />;
};

export const ICON_STATUS_OLD_IMG = () => {
	return <img src={ICON_STATUS_OLD} alt="Vanha" className="icon" />;
};

export const ICON_FILTER_ARROW_IMG = () => {
	return <img src={ICON_FILTER_ARROW} alt="Järjestä" className="icon" />;
};

export const ICON_DROPDOWN_ARROW_IMG = () => {
	return <img src={ICON_DROPDOWN_ARROW} alt="Avaa" className="icon" />;
};

export const ICON_CLOSE_IMG = () => {
	return <img src={ICON_CLOSE} alt="Sulje" className="icon" />;
};
export const ICON_SWITCH_OFF_IMG = () => {
	return <img src={ICON_SWITCH_OFF} alt="-" className="icon" />;
};
export const ICON_SWITCH_ON_IMG = () => {
	return <img src={ICON_SWITCH_ON} alt="Päällä" className="icon" />;
};
export const ICON_DUPLICATE_IMG = (props) => {
	return (
		<img {...props} src={ICON_DUPLICATE} alt="duplikoi" className="icon" />
	);
};
export const ICON_TRASH_IMG = (props) => {
	return <img {...props} src={ICON_TRASH} alt="Poista" className="icon" />;
};
