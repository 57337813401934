export const companiesInitialState = {
	loading: false,
	items: [],
};

export const companiesReducer = (
	state = companiesInitialState,
	action
) => {
	switch (action.type) {
		case 'COMPANIES_ADD':
		case 'COMPANIES_DELETE':
			return state;

		case 'COMPANIES_EDITING':
		case 'COMPANIES_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'COMPANIES_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};

		case 'COMPANIES_EDITED':
		case 'COMPANIES_ERROR':
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
};
