import React from 'react';
import { useStore } from '../../../store/useStore';

export default function ClientPackageType(props) {
	const { state } = useStore();
	const { type_id } = props;

	const pack = state?.clientpackages?.items.find((item) => {
		// eslint-disable-next-line eqeqeq
		return item.id == type_id;
	});

	if (!pack) {
		return <span>---</span>;
	}
	return <span>{pack.name}</span>;
}
