import React from 'react';
import UserName from './UserName';
import Timestamp from './Timestamp';

export default function LastModification(props) {
	const placeData = props.placeData;

	if (placeData) {
		const lastMod =
			placeData.revision_history.length > 0
				? placeData.revision_history[placeData.revision_history.length - 1]
				: false;

		if (lastMod) {
			return (
				<>
					Muokannut viimeksi: <UserName user_id={lastMod.user_id} />
					{', '}
					<Timestamp timestamp={lastMod.created_at} />
				</>
			);
		}
	}
	return <>---</>;
}
