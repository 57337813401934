export const packagesInitialState = {
	loading: false,
	items: [],
};

export const packagesReducer = (state = packagesInitialState, action) => {
	switch (action.type) {
		case 'PACKAGES_ADD':
		case 'PACKAGES_DELETE':
			return state;

		case 'PACKAGES_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'PACKAGES_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};
		default:
			return state;
	}
};
