import React from 'react';
import { useStore } from '../../../store/useStore';

export default function UserName(props) {
	const { state } = useStore();
	const { user_id } = props;

	const user = (state?.users?.items ?? []).find((item) => {
		// eslint-disable-next-line eqeqeq
		return item.id == user_id;
	});

	if (!user) {
		// console.log(user_id, state.users);
		return <></>;
	}
	return (
		<span>
			{user.first_name} {user.last_name}
		</span>
	);
}
