import { toast } from 'react-toastify';
import { replaceOrAddById } from '../App/Helpers/store';

export const usersInitialState = {
	loading: false,
	items: [],
};

export const usersReducer = (state = usersInitialState, action) => {
	switch (action.type) {
		case 'USERS_ADD':
		case 'USERS_DELETE':
			return state;

		case 'USERS_EDITING':
		case 'USERS_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'USERS_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};

		case 'USERS_EDITED':
		case 'USERS_ERROR':
			toast.success('Tiedot tallennettu');
			return {
				...state,
				loading: false,
			};

		case 'USER_SET_LOADING':
			let nitems = [...state.items];
			let nindex = nitems.findIndex((val) => {
				// eslint-disable-next-line eqeqeq
				return val.id == action.id;
			});
			if (nindex > -1) {
				nitems[nindex].loading = true;
			}

			return {
				...state,
				items: nitems,
				loading: false,
			};

		case 'USER_GET':
			const items = replaceOrAddById(
				[...state.items],
				action.payload.data.data
			);

			return {
				...state,
				items,
				loading: false,
			};

		default:
			return state;
	}
};
