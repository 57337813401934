/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
import { apiClient } from '../../apiCall';
import { useStore } from '../../../store/useStore';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../common/Loader';

export default function AddNewDestination(props) {
	// const [googledata, setGoogleData] = useState(); //{gid: "ChIJFVNymfh2jEYRsX8lhh0pZWg"}
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const [searchText, setsearchText] = useState('');
	const [timeout_id, setTimeOutId] = useState(null);
	const [googlePlaces, setGooglePlaces] = useState([]);
	const searchref = useRef();
	var history = useHistory();

	const userHasTyped = (e) => {
		let text = e.target.value;

		setsearchText(text);
		setGooglePlaces([]);

		clearTimeout(timeout_id);

		let id = setTimeout(function () {
			searchPlace();
			clearTimeout(timeout_id);
			setTimeOutId(undefined);
		}, 1000);
		setTimeOutId(id);
	};

	function searchPlace() {
		if (searchref && searchref.current) {
			let text = searchref.current.value;
			if (text.length > 2) {
				apiCall
					.GET('googleplaces/autocomplete?query=' + encodeURIComponent(text))
					.then((response) => {
						if (response?.status === 200) {
							setGooglePlaces(response.data.data);
						}
					});
			}
		}
	}

	const createManual = (text) => {
		apiCall.ADD('destinations', { name: text }).then((response) => {
			// if (response?.status === 200) {
			// 	history.push('/osallistu/' + response.data.data.hash);
			// }
			apiCall.GET('destinations');
			if (response?.status === 200) {
				history.push('/admin/destination/' + response.data.data.id);
			}
		});
	};

	function createPlaceFromGoogle(google_id, fallbackName) {
		apiCall
			.ADD('destinations', { name: fallbackName, googleId: google_id })
			.then((response) => {
				// if (response.status && response.status === 200) {
				// 	history.push('/osallistu/' + response.data.data.hash);
				// }
				apiCall.GET('destinations');
				if (response?.status === 200) {
					history.push('/admin/destination/' + response.data.data.id);
				}
			});
	}

	const existingPlaces =
		searchText.length >= 1
			? state.destinations.items.filter((i) => {
					return typeof i.name == 'string'
						? i.name.toLowerCase().includes(searchText.toLowerCase())
						: false;
			  })
			: [];

	return (
		<div className="addnew">
			<section key="search" className="addnewplace search right-padding">
				<h1>Luo uusi kohde</h1>
				<div className={searchText.length !== 0 ? 'wrapper box' : 'wrapper'}>
					<label className="label label-line">
						KOHTEEN NIMI
						<input
							type="text"
							autoComplete="off"
							value={searchText}
							placeholder="Kohteen nimi"
							ref={searchref}
							name="search"
							onChange={(e) => {
								userHasTyped(e);
							}}
						/>
					</label>
					{searchText.length >= 1 ? (
						<ul className="results">
							<li className="manual">
								<span className="name">{searchText}</span>
								<span className="links">
									<a
										className="create button secondary"
										onClick={() => createManual(searchText)}
									>
										Lisää manuaalisesti
									</a>
								</span>
							</li>

							{timeout_id && <Loader className={'dark'} />}

							{googlePlaces.map((gPlace, index) => (
								<li className="gplace " key={gPlace.place_id}>
									<span className="name">{gPlace.name || searchText}</span>
									<span className="links">
										<a
											className="check"
											href={gPlace.place_url}
											target="_blank"
											rel="noopener noreferrer"
										>
											Tarkista
										</a>
										<a
											className="create button secondary"
											onClick={(e) => {
												createPlaceFromGoogle(gPlace.place_id);
											}}
										>
											Luo
										</a>
									</span>
								</li>
							))}

							{existingPlaces &&
								existingPlaces.map((item, index) => {
									return (
										<li
											key={item.id}
											className={`existing ${index === 0 ? 'first' : ''}`}
										>
											<Link
												className="name"
												to={`/admin/destinations/${item.id}`}
											>
												{item.name} »
											</Link>
										</li>
									);
								})}
						</ul>
					) : null}
				</div>
			</section>
		</div>
	);
}
