import React from 'react';
import { ICON_ADD_IMG } from './../../Helpers/icons';

export const HeaderAmount = (props) => {
	return (
		<div className="common-header-amount">
			<i>{props.label}</i>
			<strong>{props.value}</strong>
		</div>
	);
};

export const HeaderSpacer = (props) => {
	return <div className="common-header-spacer"></div>;
};

export const HeaderAdd = (props) => (
	<div className="common-header-add right">
		<button className="button" onClick={props.add}>
			Lisää uusi <ICON_ADD_IMG />
		</button>
	</div>
);
