import React, { useEffect } from 'react';
import { useStore } from '../../../store/useStore';
import { apiClient } from '../../apiCall';
import Loader from './Loader';

export const EnvInfo = () => {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	useEffect(() => {
		// eslint-disable-next-line eqeqeq
		if (state.common.env == false && !state.common.loading) {
			apiCall.GET('info/which-env');
			// apiCall.action("GET_ENV");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// const information = { env : env:};
	const information = state.common;

	if (!information) return <Loader />;

	return (
		<div className="versionInfo">
			<div>
				{/* {process && (process?.env?.REACT_APP_ENV ?? '')} */}
				<br />
				{information.env && `env: ${information.env.env}`}
				<br />
				{information.env && `db: ${information.env.db}`}
			</div>
		</div>
	);
};
