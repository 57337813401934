import React from 'react';
import { HeaderAmount, HeaderAdd } from './HeaderAmount';

export default function PlacesHeader(props) {
	// const { state, dispatch } = useStore();
	// const data = props.data ? props.data : [];
	const dataInView = props.dataInView ? props.dataInView : [];

	// const isPlaces = props.events ? false : true;

	// const countStarted = data.filter((i) => i.status === 0).length;
	// const countForm = data.filter(
	// 	(i) => i.is_published !== 1 && i.status === 1 && i.is_paid === 0
	// ).length;
	// const countPaid = data.filter(
	// 	(i) => i.is_published !== 1 && i.status === 1 && i.is_paid === 1
	// ).length;
	// const countApproved = data.filter((i) => i.is_published === 1).length;
	const countPublished = dataInView.filter((i) => i.is_published === 1).length;
	// const countInView = dataInView.length;

	return (
		<>
			<div className="common-list-data">
				<HeaderAmount label="Yhteensä" value={dataInView.length} />
				<HeaderAmount label="Julkaistuja" value={countPublished} />
				{/* {countInView > 0 && (
					<>
						<HeaderSpacer />
						<HeaderAmount label="Näkymässä:" value={countInView} />
					</>
				)} */}
			</div>
			<HeaderAdd add={props.add} />
			{/* <div className="table-cell right-cell">
				{isPlaces && (
					<Link to={`/admin/add-destination`}>
						Lisää uusi kohde <img src={ICON_ADD} alt="Lisää uusi paikka" />
					</Link>
				)}
			</div> */}
		</>
	);
}
