export const accountInitialState = {};

export const instagramInitialState = {
	accounts: [],
	isLoading: false,
};

export const instagramReducer = (state = instagramInitialState, action) => {

	switch (action.type) {
		case 'INSTAGRAM_LOADING':
			return {
				...state,
				isLoading: true,
			};
		case 'INSTAGRAM_CACHE':
			return {
				...state,
				isLoading: false,
			};
		case 'INSTAGRAM_GET':
			const newState = {
				...state,
				isLoading: false,
				accounts: {
					...state.accounts,
					[action.username]: action.payload.data.response,
				},
			};
			return newState;
		default:
			return state;
	}
};
