import React from 'react';
import { config } from '../../config';
export default React.memo(function GoogleMapsEmbed(props) {
	const mode = props.placeId ? 'place' : 'view';

	if (mode === 'view' && (!props.lat || !props.lng)) {
		return <></>;
	}
	if (mode === 'place' && !props.placeId) {
		return <></>;
	}

	const url =
		mode === 'view'
			? `&center=${props.lat},${props.lng}`
			: `&q=place_id:${props.placeId}&center=${props.lat},${props.lng}`;

	return (
		<iframe
			title="Google Maps"
			src={`https://www.google.com/maps/embed/v1/${mode}?${url}&key=${config.mapsApiKey}&zoom=16`}
			className="maps-embed"
		></iframe>
	);
});
