export const getRequiredFields = (placeData) => {
	var fields = [
		// // "name",
		// // "location",
		// // "phone",
		// // "www",
		// // "category",
		// // "thumb_id",
		// 'confirm',
		// 'region_id',
		// // 'package_id',
		// 'location',
		// // "category",
		// 'name',
		// 'description',
		// // "additional_description",
		// 'www',
		// // "www_shop",
		// // "www_safety",
		// 'primary_www',
		// 'phone',
		// // "show_opentimes",
		// // "is_paid",
		// // "is_published",
		// // "ig_account",
		// // "fb_account",
		// 'thumb_id',
		// // "contact_user_id",
		// 'contact_first_name',
		// 'contact_last_name',
		// 'contact_email',
		// 'contact_phone',
		// // "company_name",
		// 'company_vatnumber',
		// 'company_industry',
		// 'company_size',
		// // "message",
	];

	return fields;
};

export const translateError = (error) => {
	const names = {
		name: 'Nimi',
		name_se: 'Nimi (SE)',
		name_en: 'Nimi (EN)',
		description: 'Kuvaus',
		description_se: 'Kuvaus (SE)',
		description_en: 'Kuvaus (EN)',
		www: 'WWW-osoite',
		category: 'Kategoriat',
		location: 'Sijainti',
		thumb_id: 'Kuva',
		package_id: 'Pakettityyppi',
		company_size: 'Henkilömäärä',
	};
	return names[error] ? names[error] : error;
	// return names[error] ? names[error] : "Virhe";
};

export const getErrors = (placeData) => {
	const fields = getRequiredFields(placeData);
	const errors = fields.reduce((acc, cur) => {
		if (!placeData[cur]) {
			acc.push(cur);
		} else {
			if (
				['www', 'www_shop', 'www_safety'].includes(cur) &&
				!isValidURL(placeData[cur])
			) {
				acc.push(cur);
			}
		}
		return acc;
	}, []);

	return errors;
};

export const isRequiredKey = (key, placeData) => {
	const requiredFields = getRequiredFields(placeData);
	return requiredFields && requiredFields.includes(key);
};

export const isValidURL = (str) => {
	const pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // fragment locator
	return !!pattern.test(str);
};

export const goToField = (field) => {
	const element = document.querySelector(`form [name='${field}']`);
	if (element) {
		element.focus();
	}
};

export const urlWithHttp = (url) =>
	!/^https?:\/\//i.test(url) ? `http://${url}` : url;

export const validateKohde = (data) => {
	// kohteen numero,
	// kuva,
	// gps,
	// alue,
	// kartta,
	// kategoria,
	// www
	var errors = [];

	if (data) {
		
		if (!(data.thumb_id > 0)) {
			errors.push({ type: 'error_thumb', label: 'Kohteelta puuttuu kuva' });
		}

		// console.log('isdestination', isDestination(data), isCompany(data));

		if (isDestination(data)) {
			if (!(data.kohde_numero > 0)) {
				errors.push({ type: 'error_number', label: 'Kohteen numero puuttuu' });
			}
			if (!data.slug) {
				errors.push({
					type: 'error_slug',
					label: 'Kohteelta puuttuu slugi, tallenna kohde',
				});
			}
			if (!(data?.location[0]?.lat > 0 && data?.location[0]?.lng > 0)) {
				errors.push({
					type: 'error_location',
					label: 'Tarkista kohteen GPS koordinaatit',
				});
			}
			if (!data.map_marker) {
				errors.push({
					type: 'error_map_marker',
					label: 'Kohteen karttamerkintä (kuva) puuttuu',
				});
			}
			if (!data.category || data.category.length < 1) {
				errors.push({
					type: 'error_cat',
					label: 'Kategoria puuttuu, valitse vähintään yksi',
				});
			}

			if (!data.region2021 || data.region2021.length < 1) {
				errors.push({
					type: 'error_region2021',
					label: 'Alue puuttuu, valitse vähintään yksi',
				});
			}
		}
		if (isCompany(data)) {
			if (!data.companycategory || data.companycategory.length < 1) {
				errors.push({
					type: 'error_companycat',
					label: 'Kategoria puuttuu, valitse vähintään yksi',
				});
			}
			if (!data.companyregion || data.companyregion.length < 1) {
				errors.push({
					type: 'error_companyregion',
					label: 'Alue puuttuu, valitse vähintään yksi',
				});
			}
		}
		if (!data.www) {
			errors.push({ type: 'error_www_marker', label: 'WWW -osoite puuttuu' });
		}
	}
	return errors;
};

export const isCompany = (data) => {
	return (data.modelClass === "App\\Model\\CompanyModel");
}
export const isDestination = (data) => {
	return (data.modelClass === "App\\Model\\DestinationModel");
}