export const config = {
	isDev: process.env.NODE_ENV !== 'production',
	// isDev: false,
	apiUrl:
		process.env.NODE_ENV === 'production'
			? 'https://api.100syyta.fi/api/v1'
			: process.env.NODE_ENV === 'development'
			? 'http://api.100syyta.fi:81/api/v1'
			: 'https://api.100syyta.fi/api/v1',

	mapsApiKey: 'AIzaSyBZ_r6abs3a5c8_WH26ryAc0Iu8qdapUA0',
	siteUrl:
		process.env.NODE_ENV === 'production'
			? // ? 'https://100syyta.fi'
			  'http://suoma100-2024.atk.dqcomms.com'
			: 'http://suoma100-2024.atk.sfbagency.com:81',
	appUrl:
		process.env.NODE_ENV === 'production'
			? 'https://yrityksille.100syyta.fi'
			: 'localhost:3000',

	maxFileSize: 10 * 1024 * 1024,
};
