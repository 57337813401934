import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useStore } from '../../../store/useStore';
import { config } from '../../config';
import Loader from '../common/Loader';
import ClientPackageType from '../common/ClientPackageType';
import PlacesHeader from './PlacesHeader';
import queryString from 'query-string';
import { validateKohde } from '../../Helpers/PlaceHelpers';

import Timestamp from '../common/Timestamp';
import CustomTable from '../common/CustomTable';
import {
	// ICON_CHECK_IMG,
	ICON_STATUS_LIVE_IMG,
	ICON_STATUS_HIDDEN_IMG,
} from '../../Helpers/icons';

export const ToggleErrors = ({ row }) => {
	const [show] = useState(false);

	const value = validateKohde(row.original);

	return (
		<div className="toggle-errors">
			{/* <button
				className="secondary"
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					setShow(!show);
				}}
			>
				⚠️
			</button> */}
			{!show && (
				<div className="errors">
					{value &&
						value.map((i) => {
							return <p key={`${i.type}`}>⚠️ {i.label}</p>;
						})}
				</div>
			)}
		</div>
	);
};

export default function PlacesList({ dashboardView, tableConfig, preFilter }) {
	const { state } = useStore();
	const [filter, setFilter] = useState();
	let location = useLocation();
	var history = useHistory();

	const onRowClick = (row) => {
		history.push('/admin/destination/' + row.original.id);
	};

	// const {
	// 	destinations: { items: rawData },
	// } = state;

	const { destinations: dataSource } = state;
	const { items } = dataSource;

	const params = queryString.parse(location.search);
	if (params?.filter) {
		switch (params.filter) {
			default:
				break;

			case 'validate':
				preFilter = (i) => {
					const validateErrors = validateKohde(i);
					// console.log(validateErrors);
					return validateErrors.length > 0;
				};
				break;
			case 'old':
				// eslint-disable-next-line eqeqeq
				preFilter = (i) => i.is_old == 1;
				break;
			case 'missingimages':
				preFilter = (i) => !i.thumb_id;
				break;
			case 'missingig':
				preFilter = (i) => !i.ig_account;
				break;
			case 'video':
				preFilter = (i) => i.video_url;
				break;
			case 'google':
				preFilter = (i) => i.googleId;
				break;
			case 'inapproval':
				preFilter = (i) => i.is_sent && !i.is_published;
				break;
			case 'editingdone':
				preFilter = (i) => i.is_ok && !i.is_sent && !i.is_published;
				break;
			case 'notdone':
				preFilter = (i) => !i.is_ok && !i.is_sent && !i.is_published;
				break;
			case 'published':
				preFilter = (i) => !!i.is_published;
				break;
		}
	}

	const preCustomFilter = preFilter
		? preFilter
		: () => {
				return true;
		  };

	// const processedRows = rawData.map((item) => {
	// 	const newItem = { ...item };
	// 	return newItem;
	// });

	const processedRows = items
		.map((item) => {
			const newItem = { ...item };
			return newItem;
		})
		.filter(preCustomFilter)
		.reverse();

	const data = React.useMemo(() => {
		if (processedRows) {
			if (dashboardView) {
				return processedRows.sort((a, b) => {
					return new Date(b.updated_at) - new Date(a.updated_at);
				});
			} else {
				return processedRows;
			}
		} else {
			return [];
		}
	}, [dashboardView, processedRows]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Kuva',
				accessor: 'thumb', // accessor is the "key" in the data
				Cell: ({ value, row }) => {
					if (!value) return '';

					return (
						<Link
							to={{
								pathname: '/admin/destination/' + row.original.id,
							}}
						>
							<img
								src={config.apiUrl + '/file/' + value.id}
								alt=" "
								className="thumb mini"
							/>
						</Link>
					);
				},
			},
			{
				Header: 'Nimi',
				accessor: 'name',
				Cell: ({ value, row }) => {
					return (
						<Link
							to={{
								pathname: '/admin/destination/' + row.original.id,
							}}
						>
							{value ? value : `Nimeämätön kohde (${row.original.id})`}
						</Link>
					);
				},
			},
			// {
			// 	Header: 'Koko',
			// 	accessor: 'company_size',
			// 	Cell: ({ value }) => {
			// 		return value == 2 ? '5+' : '1-5';
			// 	},
			// },
			// {
			// 	Header: 'Paketti',
			// 	accessor: 'package_id',
			// 	Cell: ({ value }) => {
			// 		return <PackageType type_id={value} />;
			// 	},
			// },
			{
				Header: 'Paketti',
				accessor: 'clientpackage_id',
				Cell: ({ value }) => {
					return <ClientPackageType type_id={value} />;
				},
			},
			{
				Header: 'Validointi?',
				id: 'validation',
				// accessor: 'category',
				// accessor: (row) => {
				// 	return '';
				// },
				accessor: 'ig_account',
				Cell: ({ row }) => {
					// return value && value.length > 0 ? '⚠️' : <ICON_CHECK_IMG />;

					return <ToggleErrors row={row} />;
					// return (
					// 	<ToggleErrors>
					// 		{value &&
					// 			value.map((i) => {
					// 				return (
					// 					<p key={`${row.original.id}-${i.type}`}>⚠️ {i.label}</p>
					// 				);
					// 			})}
					// 	</ToggleErrors>
					// );
				},
			},
			// {
			// 	Header: 'Kategoria',
			// 	accessor: 'category',
			// 	Cell: ({ value }) => {
			// 		return value && value.length > 0 ? <ICON_CHECK_IMG /> : <></>;
			// 	},
			// },
			// {
			// 	Header: 'IG',
			// 	accessor: 'ig_account',
			// 	Cell: ({ value }) => {
			// 		return value ? <ICON_CHECK_IMG /> : <></>;
			// 	},
			// },
			// {
			// 	Header: 'Kauppa',
			// 	accessor: 'webshop_id',
			// 	Cell: ({ value }) => {
			// 		return value ? <ICON_CHECK_IMG /> : <></>;
			// 	},
			// },
			{
				Header: 'Muokattu',
				accessor: 'updated_at',
				Cell: ({ value }) => {
					return <Timestamp timestamp={value} pure={true} />;
				},
			},
			// {
			// 	Header: '100',
			// 	accessor: 'is_100syyta',
			// 	Cell: ({ value }) => {
			// 		return value === 1 ? (
			// 			<ICON_STATUS_LIVE_IMG />
			// 		) : (
			// 			<ICON_STATUS_HIDDEN_IMG />
			// 		);
			// 	},
			// },
			// {
			// 	Header: 'L',
			// 	accessor: 'status',
			// 	Cell: ({ value }) => {
			// 		return value === 1 ? (
			// 			<ICON_STATUS_LIVE_IMG />
			// 		) : (
			// 			<ICON_STATUS_HIDDEN_IMG />
			// 		);
			// 	},
			// },
			// {
			// 	Header: 'M',
			// 	accessor: 'is_paid',
			// 	Cell: ({ value }) => {
			// 		return value === 1 ? (
			// 			<ICON_STATUS_LIVE_IMG />
			// 		) : (
			// 			<ICON_STATUS_HIDDEN_IMG />
			// 		);
			// 	},
			// },
			{
				Header: 'Live?',
				accessor: 'is_published',
				Cell: ({ value }) => {
					return value === 1 ? (
						<ICON_STATUS_LIVE_IMG />
					) : (
						<ICON_STATUS_HIDDEN_IMG />
					);
				},
			},
		],
		[]
	);

	if (state.destinations.loading === true || !state.destinations.items) {
		return <Loader />;
	}

	return (
		<section className="common-list places-list right-padding">
			<div className="common-list-header places-list-header ">
				<h1>Kohteet</h1>
				<PlacesHeader
					data={state.destinations.items}
					dataInView={data}
					add={() => history.push('/admin/add-destination')}
				/>
			</div>
			<input
				placeholder="Etsi"
				name="etsi"
				type="text"
				value={filter}
				onChange={(e) => setFilter(e.target.value)}
			/>
			{filter && filter !== '' && <h3>{`Kohteita hakusanalla '${filter}'`}</h3>}
			<CustomTable
				data={data}
				columns={columns}
				onRowClick={onRowClick}
				filter={filter}
			/>
		</section>
	);
}
