import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useStore } from '../../../store/useStore';
import PlacesHeader from '../../Components/admin/PlacesHeader';
// import CompaniesHeader from '../../Components/admin/CompaniesHeader';
import Timestamp from '../../Components/common/Timestamp';
import CustomTable from '../../Components/common/CustomTable';
import { config } from '../../config';
import LastModification from '../../Components/common/LastModification';
import { isCompany } from '../../Helpers/PlaceHelpers';

export default function Dashboard() {
	const { state } = useStore();
	let location = useLocation();
	var history = useHistory();

	const {
		destinations: { items: places },
		// companies: { items: companies },
	} = state;

	const lastModifiedData = React.useMemo(() => {
		if (places) {
			return places
				.sort((a, b) => {
					return new Date(b.updated_at) - new Date(a.updated_at);
				})
				.slice(0, 5);
		} else {
			return [];
		}
	}, [places]);

	// const lastModifiedCompanyData = React.useMemo(() => {
	// 	if (companies) {
	// 		return companies
	// 			.sort((a, b) => {
	// 				return new Date(b.updated_at) - new Date(a.updated_at);
	// 			})
	// 			.slice(0, 5);
	// 	} else {
	// 		return [];
	// 	}
	// }, [companies]);

	const lastModifiedColumns = React.useMemo(
		() => [
			{
				Header: 'Kuva',
				accessor: 'thumb', // accessor is the "key" in the data
				Cell: ({ value, row }) => {
					if (!value) return '';

					return (
						<Link
							to={{
								pathname: '/admin/destination/' + row.original.id,
							}}
						>
							<img
								src={config.apiUrl + '/file/' + value.id}
								alt=" "
								className="thumb mini"
							/>
						</Link>
					);
				},
			},
			{
				Header: 'Nimi',
				accessor: 'name',
				Cell: ({ value, row }) => {
					return (
						<Link
							to={{
								pathname: `/admin/${isCompany(row.original) ? 'company' : 'destination'}/${row.original.id}`,
							}}
						>
							{value ? value : `Nimeämätön kohde (${row.original.id})`}
						</Link>
					);
				},
			},
			{
				Header: 'Muokattu',
				accessor: 'updated_at',
				Cell: ({ value }) => {
					return <Timestamp timestamp={value} pure={true} />;
				},
			},
			{
				Header: 'Muokkaaja',
				accessor: 'revision_history',
				Cell: ({ value, row }) => {
					return <LastModification placeData={row.original} />;
				},
			},
		],
		[]
	);

	return (
		<>
			<h1>Dashboard</h1>
			<section className="places-list">
				<div className="places-list-header common-list-header">
					<h4>Viimeksi muokatut kohteet:</h4>
					<PlacesHeader
						data={places}
						dataInView={lastModifiedData}
						add={() => history.push('/admin/add-destination')}
					/>
				</div>

				<CustomTable
					data={lastModifiedData}
					columns={lastModifiedColumns}
					hidePagination={true}
				/>

				{/* <div className="places-list-header common-list-header">
					<h4>Viimeksi muokatut Vinkkipankki 100+ kohteet:</h4>
					<CompaniesHeader data={places} dataInView={lastModifiedCompanyData} />
				</div>
				<CustomTable
					data={lastModifiedCompanyData}
					columns={lastModifiedColumns}
					hidePagination={true}
				/> */}
			</section>
		</>
	);
}
