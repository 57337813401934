/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useStore } from '../../../store/useStore';
// import { matchPath } from 'react-router';
import { EnvInfo } from './EnvInfo';

import LOGO_IMG from '../../../images/logo2.svg';

export default function AdminNav({ location }) {
	const { state, dispatch } = useStore();
	// const isActivePath = (path) => !!matchPath(location.pathname, path);

	const navOpenState = !(state?.common?.anMinimized ?? false);

	const toggleNavOpenState = () => {
		dispatch({ type: 'TOGGLE_ADMIN_NAV' });
	};

	const logoutAction = () => {
		dispatch({ type: 'LOGOUT' });
	};
	return (
		<div className={`left ${navOpenState ? 'open' : 'minimized'}`}>
			<div className="nav fixed-nav ">
				<div className="">
					<Link to={'/admin'}>
						{/* <h2 style={{ color: '#44c8ca' }}>
							100 Syytä
							<br />
						</h2>
						*/}
						<img src={LOGO_IMG} alt="100 Syytä matkailla Suomessa" />
						<h3 style={{ color: '#ffa8a5' }}>2024</h3>
					</Link>
					<ul>
						<li>
							<NavLink activeClassName="active" exact to={'/admin'}>
								Dashboard
							</NavLink>
						</li>
						<li className="spacer"></li>
						<li className="">
							<NavLink activeClassName="active" to={'/admin/destinations'}>
								Kohteet
							</NavLink>

							<ul>
								<li key="old">
									<NavLink
										activeClassName="active"
										to={`/admin/destinations/old?filter=old`}
									>
										Vanhat kohteet
									</NavLink>
								</li>
								<li key="validate">
									<NavLink
										activeClassName="active"
										to={`/admin/destinations/validate?filter=validate`}
									>
										Tarkastettavat
									</NavLink>
								</li>
								<li key="notdone">
									<NavLink
										activeClassName="active"
										to={`/admin/destinations/notdone?filter=notdone`}
									>
										Sisällönsyöttö kesken
									</NavLink>
								</li>
								<li key="editingdone">
									<NavLink
										activeClassName="active"
										to={`/admin/destinations/editingdone?filter=editingdone`}
									>
										Sisällönsyöttö valmis
									</NavLink>
								</li>
								<li key="inapproval">
									<NavLink
										activeClassName="active"
										to={`/admin/destinations/inapproval?filter=inapproval`}
									>
										Lähetetty hyväksyntään
									</NavLink>
								</li>
								<li key="published">
									<NavLink
										activeClassName="active"
										to={`/admin/destinations/published?filter=published`}
									>
										Julkaistut
									</NavLink>
								</li>
								{/* <li key="missingimages">
									<NavLink
										activeClassName="active"
										to={`/admin/destinations/missingimages?filter=missingimages`}
									>
										Kuva puuttuu
									</NavLink>
								</li> */}
								{/* <li key="missingig">
									<NavLink
										activeClassName="active"
										to={`/admin/destinations/missingig?filter=missingig`}
									>
										IG puuttuu
									</NavLink>
								</li> */}
								<li key="video">
									<NavLink
										activeClassName="active"
										to={`/admin/destinations/video?filter=video`}
									>
										Videolliset
									</NavLink>
								</li>
								{/* <li key="google">
									<NavLink
										activeClassName="active"
										to={`/admin/destinations/google?filter=google`}
									>
										Google
									</NavLink>
								</li> */}
							</ul>
						</li>

						<li className="">
							<NavLink activeClassName="active" to={'/admin/clientpackages'}>
								Asiakkuudet
							</NavLink>
						</li>
						<li className="spacer"></li>
						{/* <li className="">
							<NavLink activeClassName="active" to={'/admin/companies'}>
								Vinkkipankki
							</NavLink>

							<ul>
								<li key="company_validate">
									<NavLink
										activeClassName="active"
										to={`/admin/companies/confirmed?filter=confirmed`}
									>
										Tiedot vahvistetut
									</NavLink>
								</li>
								<li key="company_paid">
									<NavLink
										activeClassName="active"
										to={`/admin/companies/paid?filter=paid`}
									>
										Maksetut
									</NavLink>
								</li>
								<li key="company_published">
									<NavLink
										activeClassName="active"
										to={`/admin/companies/published?filter=published`}
									>
										Julkaistut
									</NavLink>
								</li>
							</ul>
						</li> */}
						{/* <li className="">
							<NavLink activeClassName="active" to={'/admin/users'}>
								Käyttäjät
							</NavLink>
						</li>
						<li className="inactive">
							<NavLink activeClassName="active" to={'/admin/categories'}>
								Kategoriat
							</NavLink>
						</li> */}
						{/*
						<li className="inactive">
							<NavLink activeClassName="active" to={'/admin/regions'}>
								Alueet
							</NavLink>
						</li>
						<li className="inactive">
							<NavLink activeClassName="active" to={'/admin/packages'}>
								Tuotteet
							</NavLink>
						</li> */}
						{/* <li className="external">
							<a target="_blank" href={'https://www.intercom.com/'}>
								Intercom (chat)
							</a>
						</li> */}
						<li className="spacer"></li>
						<li className="external">
							{/* <a target="_blank" href={'https://100syyta.fi/'}> */}
							<a target="_blank" href={'https://suoma100-2024.atk.dqcomms.com'} rel="noreferrer">
								100Syytä.fi (2024)
							</a>
						</li>
						<li className="spacer"></li>
						<li>
							{state.user.loggedIn && (
								<a className="logout" onClick={logoutAction}>
									Kirjaudu ulos
								</a>
							)}
						</li>
					</ul>
				</div>
			</div>
			<div>
				<button className={'toggle'} onClick={toggleNavOpenState}>
					{' '}
					«»{' '}
				</button>
				<EnvInfo />
			</div>
		</div>
	);
}
