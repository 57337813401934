import React from 'react';
import {
	useTable,
	useSortBy,
	useGlobalFilter,
	usePagination,
} from 'react-table';

import { ICON_FILTER_ARROW_IMG } from '../../Helpers/icons';
import Loader from './Loader';

export default function CustomTable(props) {
	const { columns, data, hidePagination } = props;
	const onRowClick = props.onRowClick ? props.onRowClick : () => {};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		// rows,
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page

		// The rest of these things are super handy, too ;)
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		// setPageSize,
		// setGlobalFilter,
		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: 12,
				globalFilter: props.filter || '',
			},
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	if (!data) {
		return <Loader />;
	}

	if (data.length === 0) {
		return (
			<div>
				<p>...</p>
			</div>
		);
	}

	return (
		<div className="table-wrapper">
			<table
				{...getTableProps()}
				className={`ReactTable ${props.className ? props.className : ''}`}
				cellPadding={0}
				cellSpacing={0}
			>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps({
										...column.getSortByToggleProps(),
										className: column.id,
									})}
								>
									{column.render('Header')}
									<span
										className={
											column.isSorted
												? column.isSortedDesc
													? 'sorted desc'
													: 'sorted asc'
												: 'unsorted'
										}
									>
										{/* {column.isSorted ? (column.isSortedDesc ? ICON_FILTER_ARROW_IMG: " 🔼") : ""} */}
										{/* {column.isSorted ? <ICON_FILTER_ARROW_IMG /> : ""} */}
										<ICON_FILTER_ARROW_IMG />
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps({
									onClick: (event) => {
										onRowClick(row);
									},
								})}
							>
								{row.cells.map((cell) => {
									return (
										<td
											{...cell.getCellProps({
												className: cell.column.id,
											})}
										>
											{cell.render('Cell')}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>

			{!hidePagination && (
				<div className="pagination">
					<div>
						<button
							onClick={() => gotoPage(0)}
							disabled={!canPreviousPage}
							className="secondary"
						>
							{'«'}
						</button>{' '}
						<button
							onClick={() => previousPage()}
							disabled={!canPreviousPage}
							className="secondary"
						>
							{'‹'}
						</button>{' '}
					</div>
					<span>
						Sivu{' '}
						<strong>
							{pageIndex + 1} / {pageOptions.length}
						</strong>{' '}
					</span>
					<div>
						<button
							onClick={() => nextPage()}
							disabled={!canNextPage}
							className="secondary"
						>
							{'›'}
						</button>{' '}
						<button
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!canNextPage}
							className="secondary"
						>
							{'»'}
						</button>{' '}
					</div>
					{/* <span>
					| Go to page:{" "}
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={(e) => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(page);
						}}
						style={{ width: "100px" }}
					/>
				</span>{" "}
				<select
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
					}}
				>
					{[10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select> */}
				</div>
			)}
		</div>
	);
}
