const localStorageKey = 'common';

const defaultState = {
	env: false,
	anMinimized: false,
	isLoading: false,
	isUploading: false,
	primaryUrlStates: [
		{
			id: 'www',
			name: 'Yrityksen www-sivut',
		},
		{
			id: 'shop',
			name: 'Verkkokauppa / varaussivu / muu',
		},
		{
			id: 'fb',
			name: 'Facebook-sivu',
		},
	],
};

export const commonInitialState = (() => {
	let state = window.localStorage.getItem(localStorageKey)
		? {
				...defaultState,
				...JSON.parse(window.localStorage.getItem(localStorageKey)),
		  }
		: defaultState;
	state.isLoading = false;
	state.isUploading = false;
	return state;
})();

export const commonReducer = (state = commonInitialState, action) => {
	var common = state;
	switch (action.type) {
		case 'FILES_UPLOADING':
			common = { ...state, isUploading: true };
			break;
		case 'FILES_UPLOADED':
		case 'FILES_FAILED':
			common = { ...state, isUploading: false };
			break;
		case 'TOGGLE_ADMIN_NAV':
			common = { ...state, anMinimized: !state.anMinimized };
			break;
		case 'INFO/WHICH-ENV':
		case 'INFO/WHICH-ENV_GET':
			// console.log('INFO/WHICH-ENV_GET', ...(state ?? {}), ...(action.payload.data.data ?? {}));
			common = { ...state, env: { ...action.payload.data.data } };
			break;
		case 'LOGOUT':
			common = defaultState;
			break;

		default:
			return state;
	}

	window.localStorage.setItem(localStorageKey, JSON.stringify(common));

	return common;
};
