/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
/* eslint-disable import/prefer-default-export */

export const isUser = (user) => user?.role_id == 1;
export const isAdmin = (user) => user?.role_id == 2;

export const textWithLineBreaks = (text) => {
	return text
		? `<p>${text.replace(/\n{2}/g, '</p><p>').replace(/\n/g, '<br />')}</p>`
		: '';
};

export function formatPrice(price = '') {
	const parts = parseFloat(price).toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return parts.join('.');
}

export function formatPriceWithCurrency(price, currency) {
	if (!currency) currency = '€';
	const formattedPrice = price ? formatPrice(price) : '0';
	return `${formattedPrice} ${currency}`.replace(/ /g, '\u00a0');
}

export function formatInputDecimal(input) {
	return input ? input.replace(',', '.') : input;
}

export const slugify = function (text) {
	const a =
		'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b =
		'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	// return string
	// 	.toString()
	// 	.toLowerCase()
	// 	.replace(/\s+/g, '-') // Replace spaces with -
	// 	.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
	// 	.replace(/&/g, '-and-') // Replace & with 'and'
	// 	.replace(/[^\w\-]+/g, '') // Remove all non-word characters
	// 	.replace(/\-\-+/g, '-') // Replace multiple - with single -
	// 	.replace(/^-+/, '') // Trim - from start of text
	// 	.replace(/-+$/, ''); // Trim - from end of text

	return typeof text === 'string'
		? text
				.toString()
				.toLowerCase()
				.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
				.replace(/\s+/g, '-') // Replace spaces with -
				.replace(/[^\w\-]+/g, '') // Remove all non-word chars
				.replace(/\-\-+/g, '-') // Replace multiple - with single -
				.replace(/^-+/, '') // Trim - from start of text
				.replace(/-+$/, '') // Trim - from end of text
		: text;
};
