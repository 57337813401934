import React from "react";
import { formatRelative, format } from "date-fns";
import { fi } from "date-fns/locale";

export default function Timestamp(props) {
	if (!props.timestamp || props.timestamp === "0000-00-00 00:00:00") {
		return <span></span>;
	}

	const customFormat = props.format ? props.format : "dd.MM.yyyy";

	return (
		<span className="timestamp">
			{props.pure &&
				format(new Date(props.timestamp), customFormat, { locale: fi })}
			{!props.pure &&
				formatRelative(new Date(props.timestamp), new Date(), { locale: fi })}
		</span>
	);
}
