/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ICON_TRASH_IMG, ICON_DUPLICATE_IMG } from '../../Helpers/icons';
// import { PAGE_TYPE_CONTENT } from '../../Helpers/pages';

import { config } from '../../config';

import {
	CustomInput,
	CustomRichTextArea,
	CustomPictureSelector,
} from '../../Components/common/CustomInput';
import GenericSortable from './GenericSortable';
import { replaceOrAddById } from '../../Helpers/store';

const SectionEditor = ({
	data,
	label,
	field,
	apiCall,
	template,
	handleOnChange,
}) => {
	const reducer = (acc, cur) => {
		// eslint-disable-next-line eqeqeq
		let x = acc.findIndex((i) => i.id == cur.id);
		if (x !== -1) {
			acc[x] = { ...acc[x], ...cur };
		} else {
			acc.push(cur);
		}

		return acc;
	};

	const rawItems = data[field]
		? [...template, ...data[field]].reduce(reducer, [])
		: template;

	const addSection = (duplicateItem) => {
		const newItem = duplicateItem
			? { ...duplicateItem, id: createId() }
			: {
					id: createId(),
			  };
		const items = replaceOrAddById([...rawItems], newItem);

		handleOnChange({
			name: field,
			value: items,
		});
	};

	const removeSection = (removeId) => {
		if (window.confirm('Haluatko varmasti poistaa tämän?')) {
			const items = [...rawItems].filter((i) => {
				// eslint-disable-next-line eqeqeq
				return i.id != removeId;
			});

			handleOnChange({
				name: field,
				value: items,
			});
		}
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const items = reorder(
			rawItems,
			result.source.index,
			result.destination.index
		);

		handleOnChange({ name: field, value: items });
	};

	const editSectionData = (event, id) => {
		const { name, value } = event.target;

		const existingItem = rawItems.find((item) => {
			// eslint-disable-next-line eqeqeq
			return item.id == id;
		});
		existingItem[name] = value;
		const items = replaceOrAddById([...rawItems], existingItem);

		handleOnChange({
			name: field,
			value: items,
		});
	};

	const sendFile = (file, cb) => {
		if (file.size > config.maxFileSize) {
			alert('Tiedoston maksimikoko on 10MB');
			return false;
		}

		return apiCall.UPLOAD('files', file).then((response) => {
			if (response.status === 200 && response.data && response.data.data) {
				if (typeof cb == 'function') {
					cb(response.data.data.id);
				}
			}
		});
	};

	return (
		<div>
			{label && <label className="label">{label}</label>}
			<div className="page-section-editor drag-and-drop">
				<ul className={'content'}>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="list" direction="vertical">
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
									className="droppable-area"
								>
									<ItemList
										items={rawItems}
										sendFile={sendFile}
										addSection={addSection}
										onRemove={removeSection}
										handleOnChange={editSectionData}
									/>
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</ul>

				<div className="drag-and-drop-tools">
					<label className="label">Lisää uusi moduuli:</label>

					<div className={'add'}>
						<button
							onClick={() => addSection({ content: {}, component: 'html' })}
							className="button secondary"
							type="button"
						>
							Sisältö
						</button>
						<button
							onClick={() => addSection({ content: {}, component: 'image' })}
							className="button secondary"
							type="button"
						>
							Kuva
						</button>
						<button
							onClick={() => addSection({ content: {}, component: 'video' })}
							className="button secondary"
							type="button"
						>
							Video
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionEditor;

const SectionHTML = ({ item, handleOnChange }) => {
	const contentEdit = (name, event, editor) => {
		const value = editor.getData();
		item.content[name] = value;

		const newEvent = { target: { name: 'content', value: item.content } };
		handleOnChange(newEvent, item.id);
	};
	return (
		<div className={'module module-html'}>
			<label className="label">HTML - Sisältö</label>
			<div className="input-wrapper">
				<CustomRichTextArea
					label={'Teksti'}
					field={'text'}
					handleOnChange={contentEdit}
					data={item.content}
				/>
			</div>
		</div>
	);
};

const SectionImage = ({ item, handleOnChange, sendFile, isUploading }) => {
	const contentEdit = (event) => {
		const { name, value } = event.target;
		item.content[name] = value;

		const newEvent = { target: { name: 'content', value: item.content } };
		handleOnChange(newEvent, item.id);
	};

	const fileSelectorRefX = React.createRef();

	const onFileChange = (file, key) => {
		sendFile(file, (id) => {
			contentEdit({ target: { name: key, value: id } });
		});
	};

	return (
		<div className={'module module-image'}>
			<label className="label">Kuvasisältö</label>
			<div className="input-wrapper">
				<CustomInput
					label={'Alt-teksti'}
					field={'alt'}
					handleOnChange={contentEdit}
					data={item.content}
				/>
			</div>
			<div className={`input-wrapper`}>
				<label className="label">
					<span>Kuva: </span>

					<CustomPictureSelector
						data={item.content}
						field={'thumb_id'}
						handleOnChange={onFileChange}
						fileSelectorRef={fileSelectorRefX}
						autocomplete={'photo'}
						isRequired={false}
						isUploading={isUploading}
						className={'contain'}
					/>
				</label>
			</div>
		</div>
	);
};

const SectionVideo = ({ item, handleOnChange, sendFile, isUploading }) => {
	const contentEdit = (event) => {
		const { name, value } = event.target;
		item.content[name] = value;

		const newEvent = { target: { name: 'content', value: item.content } };
		handleOnChange(newEvent, item.id);
	};

	const fileSelectorRefX = React.createRef();

	const onFileChange = (file, key) => {
		sendFile(file, (id) => {
			contentEdit({ target: { name: key, value: id } });
		});
	};

	return (
		<div className={'module module-video'}>
			<label className="label">Videolinkki:</label>

			<div className="input-wrapper">
				<CustomInput
					label={'Video (VIMEO ID)'}
					field={'video'}
					handleOnChange={contentEdit}
					data={item.content}
				/>
				{item?.content?.video && (
					<div className="iframe-wrapper">
						<iframe
							src={`https://player.vimeo.com/video/${item.content.video}`}
							frameBorder="0"
							allow="autoplay; fullscreen; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
				)}
			</div>
			<div className={`input-wrapper`}>
				<label className="label">
					<span>Poster -kuva: </span>

					<CustomPictureSelector
						data={item.content}
						field={'thumb_id'}
						handleOnChange={onFileChange}
						fileSelectorRef={fileSelectorRefX}
						autocomplete={'photo'}
						isRequired={false}
						isUploading={isUploading}
						className={'share contain'}
					/>
				</label>
			</div>
		</div>
	);
};

const SectionText = ({ item, handleOnChange }) => {
	const contentEdit = (event) => {
		const { name, value } = event.target;
		item.content[name] = value;

		const newEvent = { target: { name: 'content', value: item.content } };
		handleOnChange(newEvent, item.id);
	};
	return (
		<div className={'module module-text'}>
			<label className="label">Teksti - Sisältö</label>
			<div className="input-wrapper">
				<CustomInput
					label={'Teksti'}
					field={'text'}
					handleOnChange={contentEdit}
					data={item.content}
				/>
			</div>
		</div>
	);
};

const SectionCarousel = ({ item, handleOnChange, sendFile }) => {
	const contentEdit = (event) => {
		const { name, value } = event;
		item[name] = value;

		const newEvent = { target: { name: 'content', value: item.content } };
		handleOnChange(newEvent, item.id);
	};

	return (
		<div className={'module module-carousel'}>
			<label className="label">Karuselli</label>
			<GenericSortable
				field={'content'}
				data={item}
				handleOnChange={contentEdit}
				EditableComponent={CarouselItem}
				sendFile={sendFile}
			/>
		</div>
	);
};

const CarouselItem = ({ item, handleOnChange, sendFile }) => {
	const fileSelectorRef = useRef();

	const onFileChange = (file, key) => {
		sendFile(file, (id) => {
			handleOnChange({ target: { name: 'image', value: id } }, item.id);
		});
	};

	return (
		<div className={'multi-input-wrapper'}>
			<div className="input-wrapper">
				<CustomPictureSelector
					data={item}
					field={'image'}
					handleOnChange={onFileChange}
					fileSelectorRef={fileSelectorRef}
					autocomplete={'photo'}
					isRequired={false}
					isUploading={false}
				/>
			</div>
			<div className="input-wrapper">
				<CustomInput
					label={'Otsikko'}
					field={'title'}
					handleOnChange={(event) => handleOnChange(event, item.id)}
					data={item}
				/>
			</div>
			<div className="input-wrapper">
				<CustomInput
					label={'Teksti'}
					field={'text'}
					handleOnChange={(event) => handleOnChange(event, item.id)}
					data={item}
				/>
			</div>
		</div>
	);
};

const SectionFAQ = ({ item, handleOnChange }) => {
	const contentEdit = (event) => {
		const { name, value } = event;
		item[name] = value;

		const newEvent = { target: { name: 'content', value: item.content } };
		handleOnChange(newEvent, item.id);
	};
	return (
		<div className={'module module-faq'}>
			<label className="label">UKK</label>
			<GenericSortable
				field={'content'}
				data={item}
				handleOnChange={contentEdit}
				EditableComponent={FAQItem}
			/>
		</div>
	);
};

const FAQItem = ({ item, handleOnChange }) => {
	return (
		<div className="multi-input-wrapper">
			<div className="input-wrapper">
				<CustomInput
					label={'Kysymys'}
					field={'title'}
					handleOnChange={(event) => handleOnChange(event, item.id)}
					data={item}
				/>
			</div>
			<div className="input-wrapper">
				<CustomInput
					label={'Vastaus'}
					field={'text'}
					useTextarea={true}
					handleOnChange={(event) => handleOnChange(event, item.id)}
					data={item}
				/>
			</div>
		</div>
	);
};

const Section = (props) => {
	/*
item,
	index,
	onRemove,
	addSection,
	handleOnChange,
	sendFile,
	*/
	const item = props.item;
	const index = props.index;
	let EditableComponent = false;

	switch (item.component) {
		default:
		case 'text':
			EditableComponent = <SectionText {...props} />;
			break;
		case 'html':
			EditableComponent = <SectionHTML {...props} />;
			break;
		case 'image':
			EditableComponent = <SectionImage {...props} />;
			break;
		case 'video':
			EditableComponent = <SectionVideo {...props} />;
			break;
		case 'carousel':
			EditableComponent = <SectionCarousel {...props} />;
			break;
		case 'faq':
			EditableComponent = <SectionFAQ {...props} />;
			break;
	}

	return (
		<Draggable
			draggableId={`drag-section-${item.id}`}
			index={index}
			isDragDisabled={item.fixed}
		>
			{(provided, snapshot) => (
				<li
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className={`drag-item ${item.fixed ? 'drag-disabled' : ''}`}
					style={getItemStyle(
						snapshot.isDragging,
						provided.draggableProps.style
					)}
					key={item.id}
				>
					<span className="drag-bar">|||</span>
					{EditableComponent}

					{!(item?.fixed ?? false) && (
						<div className="dnd-actions">
							<span
								className="remove"
								onClick={() => props.onRemove(item.id)}
								type="button"
							>
								<ICON_TRASH_IMG />
							</span>
							<span
								className="remove"
								onClick={() => props.addSection(item)}
								type="button"
							>
								<ICON_DUPLICATE_IMG />
							</span>
						</div>
					)}
				</li>
			)}
		</Draggable>
	);
};

const ItemList = React.memo(function ItemList(props) {
	return props.items.map((item, index) => (
		<Section item={item} index={index} key={item.id} {...props} />
	));
});

const getItemStyle = (isDragging, draggableStyle) => ({
	...draggableStyle,
});

const createId = () => {
	return '_' + Math.random().toString(36).substr(2, 9);
};

const reorder = (list, startIndex, endIndex) => {
	let result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};
