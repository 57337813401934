import React from 'react';
import { useHistory } from 'react-router-dom';
// import { useStore } from '../../store/useStore';
// import { apiClient } from '../apiCall';
// import FAQ from '../Components/common/FAQ';
// import IG_STICKER from '../../images/ig-nosto_@2x.png';

export default function Frontpage() {
	// const { state, dispatch } = useStore();
	// const apiCall = apiClient(state, dispatch);
	// eslint-disable-next-line no-unused-vars
	var history = useHistory();

	/*
		Etusivu / Syötä yrityksen nimi => ennakosyöttö googlesta  tai ‘lisää manuaalisesti’
		Mikäli google => Karttanäkymä / Vahvista että valittu tulos on oikea. Jos väärä, palaa edelliseen näkymään/syötä nimi uudelleen.
		Lomake (jaettu useampaan näkymään)
		Lomake tallennettu, siirry maksamaan
		Maksu / Paytrail:
		Maksu onnistui!
		Maksu epäonnistui!
	*/

	return (
		<div className="frontpage">
			{/* <section className="hero">
				<h1>
					Matkailuyrittäjä!
					<p>
						Haluamme koota yhteen kotimaan matkailutoimijat kuluttajalle
						inspiroivasti ja helposti löydettävästi. Siksi loimme Vinkkipankki
						100+:n – lähde mukaan!
					</p>
				</h1>
			</section> */}
			<div className="box">
				{/* <div className="cta-wrapper">
					{* <Link to={'/osallistu'} className="button primary cta">
						Osallistu tästä ›
					</Link> *}
					<p style={{ color: '#fff' }}>
						Ilmoittautuminen suljettu. Lisätietoja vuoden 2023 kampanjasta{' '}
						<a href="mailto:100syyta@dqcomms.com" style={{ color: '#fff' }}>
							100syyta@dqcomms.com
						</a>
						.
					</p>
				</div>
				<section className="grid-front intro">
					<h2>Tule mukaan tekemään mainio kotimaan matkailuvuosi – yhdessä!</h2>
					<p>
						<strong>Vinkkipankki 100+</strong> on 100 syytä matkailla Suomessa
						-sivustolle rakennettu uusi hakuelementti, jossa kuluttaja voi
						etsiä, suodattaa ja tutustua matkailuyritysten monipuoliseen
						tarjontaan ympäri Suomen.
					</p>
					<p>
						Pääset osallistumaan Vinkkipankki 100+:aan vaivattomasti lomakkeen
						täyttämällä ja pienellä panostuksella. Mukaan lähtevät yritykset
						saavat osallistumismaksua (400 € + alv.) vastaan oman
						“esittelykortin”, joka koostuu itse tuottamastasi tekstistä sekä
						kuvasta. Loput tiedot haetaan automaattisesti verkosta, Googlesta ja
						yrityksen Instagram-tililtä. Helppoa!
					</p>

					<p>Lämpimästi tervetuloa mukaan!</p>
				</section>
				<section className="grid-front">
					<h2>Miksi osallistuminen kannattaa? Neljä tärkeintä syytä:</h2>

					<ul className="highlights list">
						<li>
							<img
								src={IG_STICKER}
								className="ig-sticker"
								alt="Julkaise Instagramissa hashtagilla #100syytämatkaillasuomessa"
							/>
							<h3>1. Vaivatonta sinulle!</h3>
							<p>
								Tietojen syöttäminen lomakkeen kautta on helppoa ja nopeaa.
								Suurin osa tiedoista saadaan automaattisesti verkosta, sinulle
								maksuttomalta <strong>Google My Business</strong> -tililtä sekä
								yrityksesi <strong>Instagram-tililtä</strong>. Osallistuminen
								onnistuu myös ilman Instagram-tiliä.
							</p>
						</li>
						<li>
							<h3>2. Helppokäyttöinen kuluttajalle!</h3>
							<p>
								Kuluttajan on helppo löytää itselleen kiinnostavimmat kohteet
								hakua eri tavoin suodattamalla. Dynaamisten sisältöjen avulla
								kuluttajalle on aina tarjolla jotain uutta koettavaa.
							</p>
						</li>
						<li>
							<h3>3. Aina ajankohtainen!</h3>
							<p>
								Vinkkipankkiin päivittyy automaattisesti yrityksesi uusin
								Instagram-postaus, joten ajankohtaisten tapahtumien ja tärppien
								välittäminen kuluttajille on tehty tosi mutkattomaksi. Tätä
								varten sinun tarvitsee vain tehdä Instagram-tilillesi uusi
								julkaisu, jossa käytät tunnistetta #100syytämatkaillasuomessa.
								Se nousee sivustolle automaattisesti ja pitää yrityksesi näkymän
								aina tuoreena.
							</p>
						</li>
						<li>
							<h3>4. Vahva kampanjabrändi tukena!</h3>
							<p>
								100 syytä matkailla Suomessa -kampanja on seitsemässä vuodessa
								vakiinnuttanut asemansa kotimaanmatkailun kärkibrändinä.
								Osallistumalla olet osa isoa kokonaisuutta. Yhdessä olemme
								enemmän.
							</p>
						</li>
					</ul>
				</section> */}
				{/* <section className="faq" id="ukk">
					<h2>Usein kysyttyä</h2>
					<FAQ />
				</section> */}
				<section className="contact-us" id="ota-yhteytta">
					<h2>Ota yhteyttä</h2>
					<p>
						<a href="mailto:100syyta@dqcomms.com">100syyta@dqcomms.com</a>
					</p>
					{/* <p>
						<button
							className="secondary"
							onClick={() => {
								window.Intercom('show');
							}}
						>
							Lähetä viesti (chat)
						</button>
					</p> */}
				</section>
			</div>
		</div>
	);
}
