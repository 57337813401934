import React from 'react';
export default function Footer(props) {
	return (
		<footer>
			<div className="grid-narrow">
				<p>
					Suoma ry on Suomen paikallisten matkailuorganisaatioiden yhdistys ja
					koordinoiva voima tämän sivuston takana. Toivottavasti tykkäät!
				</p>
			</div>
			<ul>
				<li>
					<a href="/kayttoehdot">Käyttöehdot</a>
				</li>
				<li>
					<a href="/rekisteriseloste">Rekisteriseloste</a>
				</li>
			</ul>
		</footer>
	);
}
